import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import jwtDecode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		apiURL: "/api",
		jwtToken: undefined,
		jwtClaims: {},
		boardActiveView: {} as {[board: string]: string},
	},
	mutations: {
		setLocalAPI(state) {
			state.apiURL = "http://localhost:3000/api";
		},
		setBoardActiveView(state, [board, activeView]) {
			state.boardActiveView[board] = activeView;
		},
		setJWTToken(state, newToken) {
			state.jwtToken = newToken;
			try {
				state.jwtClaims = jwtDecode(newToken);
			} catch {
				state.jwtClaims = {};
			}
		},
	},
	actions: {

	},
	plugins: [createPersistedState()],
});
