<template>
	<div>
		<headful title="Logout" />
		<v-card style="max-width: 440px; margin: auto;">
			<v-toolbar color="primary">
				<h1 style="color: white; margin: auto">Logging Out</h1>
			</v-toolbar>
		</v-card>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	export default Vue.extend({
		mounted() {
			this.$nextTick(function() {
				this.$store.commit("setJWTToken", undefined);
				this.$router.replace("/");
			});
		},
	});
</script>