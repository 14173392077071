import Vue from "vue";
import Vuetify from "vuetify";
//import "vuetify/src/stylus/app.styl";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
	iconfont: "fa",
	theme: {
		themes: {
			light: {
				"primary": "#31b9a9",
				"secondary": "#424242",
				"accent": "#009688",
				"error": "#FF5252",
				"info": "#ffc107",
				"success": "#4CAF50",
				"warning": "#FB8C00",
			},
		},
	},
});

import VuetifyToast from "vuetify-toast-snackbar";
Vue.use(VuetifyToast, {
	showClose: false,
	color: "#403e4d",
});

import headful from "vue-headful";
Vue.component("headful", headful);

import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll); // TODO: Do we still need this?

import VueDragDrop from "vue-drag-drop";
Vue.use(VueDragDrop);
