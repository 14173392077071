import { render, staticRenderFns } from "./Habits.vue?vue&type=template&id=1f3eaa07&scoped=true"
import script from "./Habits.vue?vue&type=script&lang=ts"
export * from "./Habits.vue?vue&type=script&lang=ts"
import style0 from "./Habits.vue?vue&type=style&index=0&id=1f3eaa07&prod&lang=css"
import style1 from "./Habits.vue?vue&type=style&index=1&id=1f3eaa07&prod&scoped=true&lang=css"
import style2 from "./Habits.vue?vue&type=style&index=2&id=1f3eaa07&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3eaa07",
  null
  
)

export default component.exports