<template>
	<div>
		<headful title="Task Overview" />
		<v-card :style="{'max-width': (activeView == 'Kanban' ? 'unset' : '860px'), 'margin': 'auto'}">
			<v-toolbar color="primary" dark>
				<h2 style="color: white; margin-left: 4px;">Task Overview</h2>
				<v-layout align-center justify-end>
				<v-flex shrink style="min-width: 150px;">
					<v-select v-model="activeView" :items="['Tasks', 'Calendar', 'Kanban']"
					background-color="teal darken-2" solo class="text-right view-switcher" @input="saveActiveView"
					style="font-weight: bold; transform: scale(0.8); margin-top: 20px; text-align: right;"/>
				</v-flex>
				</v-layout>
			</v-toolbar>
			<div v-if="['Calendar', 'Tasks'].includes(activeView)">
				<br>
				<div v-if="activeView == 'Calendar'" style="padding: 0 20px;">
					<FullCalendar defaultView="dayGridMonth" :plugins="calendarPlugins" 
					:events="calendarEvents" :header="calConfig.header"
					@eventClick="calendarEventClick" :eventRender="calendarEventRender"/>
				</div>
				<div v-if="activeView == 'Tasks'">
					<v-list dense two-line subheader>
						<div>
							<v-list-item  :id="'tasklistentry-'+cat+'-'+taskIndex" class="elevation-2"
							v-for="([boardID, cat, taskIndex, task], chronoIndex) in chronoTaskList" :key="task._id"
							ripple style="margin: 15px 10px; overflow: hidden; border: 1px solid #007a6e; border-radius: 5px;">
								<v-list-item-avatar color="teal" style="transform: scale(0.75);" :draggable="false">
									<span class="white--text headline">{{chronoIndex+1}}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<div style="font-size: 110%; margin: 12px 0;"> 
										{{task.name}}
									</div>
									<v-divider style="margin-bottom: 10px;"/>
									<div style="font-size: 80%; text-align: left; margin-left: 4px; margin-bottom: 4px;">
										<span v-html="boardNameMap[boardID]"
										style="color: black; background-color: #ddd; font-size: 90%; padding: 2px 8px; text-align: 'center'; 'border-radius': '4px';"/>
										-
										<span v-html="cat"
										style="color: black; background-color: #ddd; font-size: 90%; padding: 2px 8px; text-align: 'center'; 'border-radius': '4px';"/>
										&nbsp;&nbsp;|&nbsp;&nbsp;
										<span v-if="task.vagueDueDate != undefined || task.dueDate != undefined"
										:style="{'color': taskOverdue(task) ? '#da3434' : 'black'}">
											Due: {{task.vagueDueDate || toHumanTime(task.dueDate)}}
										</span>
										<span v-else>
											Due: ???
										</span>
										<span v-if="(task.activeSubtasks.length + task.completedSubtasks.length) > 0">
											&nbsp;&nbsp;|&nbsp;&nbsp;
											Subtasks: <sup>{{task.completedSubtasks.length}}</sup>&frasl;<sub>{{task.activeSubtasks.length + task.completedSubtasks.length}}</sub>
										</span>
									</div>
								</v-list-item-content>

								<v-list-item-action style="transform: scale(0.8);">
									<v-btn fab small depressed color="primary"
									:to="'/board/' + boardID + '/' + encodeURI(cat) + '/' + taskIndex">
										<v-icon style="font-size: 1.5em">fa-chevron-right</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</div>
					</v-list>
					<div v-if="this.chronoTaskList.length < 1" style="padding: 20px 10px;">
						<h3>
							All tasks completed, good job!
						</h3>
					</div>
				</div>
			</div>
		</v-card>
		<div v-if="activeView == 'Kanban'" style="width: 100%; overflow-x: auto; overflow-y: show;">
			<div style="display: flex; justify-content: flex-start;">
				<v-card v-for="(board, boardID) in chronoTaskListKanbanBoards" :key="boardID" 
				class="kanban-card kanban-draggable" style="min-height: 180px;">
					<v-toolbar dark height=36 class="kanban-draghandle" style="cursor: pointer;"
					@click="$router.push('/board/' + boardID)">
						<h4 :style="{'margin': 'auto', 'color': contrastingTextColor('#757575')}">{{boardNameMap[boardID]}}</h4>
					</v-toolbar>
					<div style="height: calc(100% - 36px);">
						<div v-for="([boardID, cat, _, task], taskIndex) in board" :key="task._id"
						style="background-color: #e0e0e0; border: 1px solid #ccc; box-shadow: 2px 2px 2px #ddd; border-radius: 4px; margin: 14px 4px;">
							
							<div @click="$router.push('/board/' + boardID + '/' + encodeURI(cat) + '/' + taskIndex)" style="cursor: pointer; margin: 8px; white-space: pre-wrap;">{{task.name}}</div>
							
							<div style="height: 1px; background-color: #444; margin-bottom: 8px;"/>
							<br>
							<v-list-item-subtitle style="text-align: left; margin-top: -22px; margin-left: 8px; margin-bottom: 6px; font-size: 90%;">
								<span style="clear: none;">
									<span v-if="task.vagueDueDate != undefined || task.dueDate != undefined"
									:style="{'color': taskOverdue(task) ? '#da3434' : 'black' }">
										<v-icon style="font-size: 100%; margin: -4px 2px 0 0; color: inherit;">fa-calendar-alt</v-icon>
										{{task.vagueDueDate || toHumanTimeShort(task.dueDate)}}
									</span>
									<span v-else>
										Due: ?
									</span>
								</span>
								<span v-if="(task.activeSubtasks.length + task.completedSubtasks.length) > 0"
								style="float: right; display: inline-block; margin-right: 6px; cursor: pointer;">
									<v-icon style="font-size: 90%; margin: -2px 4px 0 0;">fa-check</v-icon>
									<sup>{{task.completedSubtasks.length}}</sup>&frasl;<sub>{{task.activeSubtasks.length + task.completedSubtasks.length}}</sub>
								</span>
							</v-list-item-subtitle>
						</div>
						<!--<div>
							<v-textarea outlined class="quickadd-textarea" :id="'quickadd-textarea-' + catIndex" auto-grow rows="1" small placeholder="New Task" @keyup.ctrl.enter="quickAddTask(cat, $event.target.value, false); clearTextareaElement($event.target)"
							style="padding: 5px; border-radius: 4px; width: calc(100% - 10px); margin: 0 5px; font-size: 100%;"/>
							<v-btn small style="width: calc(100% - 20px); margin: 2px 10px 10px 10px;" color="primary"
							@click="quickAddTask(cat, document.getElementById('quickadd-textarea-' + catIndex).value, false); clearTextareaElement(document.getElementById('quickadd-textarea-' + catIndex))">
								<v-icon :clickable="false">fa-plus</v-icon>
							</v-btn>
						</div>-->
					</div>
				</v-card>
			</div>
		</div>
	</div>
</template> 

<script lang="ts">
import Vue from "vue";
import { TaskBoard, Task, Subtask } from "@/doertypes";
import axios from "axios";
import { Mongoose } from "mongoose";
import mongoose from "mongoose";
import draggable from "vuedraggable";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import Tooltip from "tooltip.js";

export default Vue.extend({
	components: {
		draggable,
		FullCalendar,
	},
	data() {
		return {
			vagueDates: ["ASAP", "Now", "Soon", "Later", "Eventually"],
			activeView: "Tasks",
			taskOverview: {} as {[id: string]: {[cat: string]: Task[]}},
			boardNameMap: {} as {[id: string]: string},
			saveTimeout: undefined as number | undefined,
			calendarPlugins: [ dayGridPlugin ],
			calConfig: {
				header: {
					left: "prev,next,today",
					center: "title",
					right: "dayGridMonth,dayGridWeek,dayGridDay",
				},
			},
			windowFocused: true,
			refreshTimeoutInterval: 10, //time between auto-refreshes (in minutes)
			refreshTimeout: undefined as any,
		};
	},
	methods: {
		fetchTaskOverview() {
			return axios.get(this.$store.state.apiURL + "/boards/overview", this.axiosConfig)
			.then(resp => {
				this.taskOverview = resp.data[0];
				this.boardNameMap = resp.data[1];
				//TODO: is allocating a new timeout every time inefficient??
				clearInterval(this.refreshTimeout);
				this.refreshTimeout = setTimeout(() => {
					this.fetchTaskOverview();
				}, this.refreshTimeoutInterval  * 60 * 1000);
			})
			.catch(() => {
				this.$router.push("/");
			});

		},
		saveActiveView() {
			this.$store.commit("setBoardActiveView", ["overview", this.activeView]);
		},
		calendarEventClick(e: any) {
			document.querySelectorAll(".tooltip").forEach((x) => (x as HTMLElement).style.visibility = "hidden");
			this.$router.push("/board/" + e.event.extendedProps.boardID + "/" + encodeURI(e.event.extendedProps.cat) + "/" + e.event.extendedProps.i);
		},
		calendarEventRender(info: any) {
			const tooltip = new Tooltip(info.el, {
				title: info.event.title,
				placement: "top",
				trigger: "hover",
				container: "body",
			});
		},

		/*completeTask(taskCat: string, taskIndex: number) {
			if (!this.board.completedTasks) this.board.completedTasks = {};
			if (this.board.activeTasks[taskCat][taskIndex].activeSubtasks.length > 0) {
				if (!confirm("Are you sure you want to delete a task with active subtasks?")) {
					return;
				}
			}
			const completedTask = this.board.activeTasks[taskCat].splice(taskIndex, 1);
			if (!this.board.completedTasks[taskCat]) this.board.completedTasks[taskCat] = [];
			this.board.completedTasks[taskCat].push(completedTask[0]);
			this.saveBoard();
		},
		uncompleteTask(taskCat: string, taskIndex: number) {
			const uncompletedTask = this.board.completedTasks[taskCat].splice(taskIndex, 1);
			this.board.activeTasks[taskCat].push(uncompletedTask[0]);
			this.saveBoard();
		},
		completeSubtask(subtaskIndex: number) {
			if (this.editTaskDialog === undefined) return;
			if (!this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks) this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks = [];
			const completedSubtask = this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks.splice(subtaskIndex, 1);
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks.push(completedSubtask[0]);
			this.saveBoard();
		},
		uncompleteSubtask(subtaskIndex: number) {
			if (this.editTaskDialog === undefined) return;
			if (!this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks) this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks = [];
			const uncompletedSubtask = this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks.splice(subtaskIndex, 1);
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks.push(uncompletedSubtask[0]);
			this.saveBoard();
		}, */
		toHumanTime(date: string | undefined): string {
			if (!date) return "";
			if (this.vagueDates.includes(date)) return date;
			const parsedDate = moment(date);
			return parsedDate.isValid() ? parsedDate.format("MMM Do YYYY") : date;
		},
		toHumanTimeShort(date: string | undefined): string {
			if (!date) return "";
			if (this.vagueDates.includes(date)) return date;
			const parsedDate = moment(date);
			return parsedDate.isValid() ? parsedDate.format("M/D/YY") : date;
		},
		vagueToDayDiff(v: string | undefined): number {
				if (v === "ASAP") return 0.5;
				if (v === "Now") return 1.5;
				if (v === "Soon") return 14;
				if (v === "Later") return 60;
				if (v === "Eventually") return 365 * 10;
				return 30;
		},
		taskOverdue(task: Task) {
			if (task.dueDate != undefined && moment(task.dueDate).diff(moment(new Date()), "days") < 1) return true
			if (task.vagueDueDate != undefined && this.vagueToDayDiff(task.vagueDueDate) < 1) return true
			return false
		},
		taskDueSort(aTask: [string, string, number, Task], bTask: [string, string, number, Task]): number {
			// Prefer to use diff between exact due date and today
			// else will use vague date if available
			// else will use the date the task was created (most recent tasks will be at the top)
			const aa = (aTask[3].dueDate ? moment(aTask[3].dueDate).diff(moment(new Date()), "days", true) : undefined) || this.vagueToDayDiff(aTask[3].vagueDueDate) || moment(new mongoose.Types.ObjectId(aTask[3]._id).getTimestamp()).diff(moment(new Date()), "days");
			const bb = (bTask[3].dueDate ? moment(bTask[3].dueDate).diff(moment(new Date()), "days", true) : undefined) || this.vagueToDayDiff(bTask[3].vagueDueDate) || moment(new mongoose.Types.ObjectId(aTask[3]._id).getTimestamp()).diff(moment(new Date()), "days");
			return (aa > bb) ? 1 : -1;
		},
		addWindowBlurListeners() {
			const browserPrefixes = ["moz", "ms", "o", "webkit"];
			// get the correct attribute name
			function getHiddenPropertyName(prefix: string | undefined): string {
				return (prefix ? prefix + "Hidden" : "hidden");
			}

			// get the correct event name
			function getVisibilityEvent(prefix: string | undefined) {
				return (prefix ? prefix : "") + "visibilitychange";
			}

			// get current browser vendor prefix
			function getBrowserPrefix() {
				for (const prefix of browserPrefixes) {
					if (getHiddenPropertyName(prefix) in document) {
						// return vendor prefix
						return prefix;
					}
				}

				// no vendor prefix needed
				return undefined;
			}

			// bind and handle events
			const browserPrefix = getBrowserPrefix();
			const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
			const visibilityEventName = getVisibilityEvent(browserPrefix);

			const onVisible = () => {
				// prevent double execution
				if (this.windowFocused) {
					return;
				}
				// change flag value
				this.windowFocused = true;
				// Update board data
				return this.fetchTaskOverview();
			};

			const onHidden = () => {
				// prevent double execution
				if (!this.windowFocused) {
					return;
				}
				// change flag value
				this.windowFocused = false;
			};

			function handleVisibilityChange(forcedFlag: boolean | Event) {
			// forcedFlag is a boolean when this event handler is triggered by a
			// focus or blur eventotherwise it's an Event object
				if (typeof forcedFlag === "boolean") {
					if (forcedFlag) {
						return onVisible();
					}
					return onHidden();
				}
				if ((document as any)[hiddenPropertyName]) {
					return onHidden();
				}
				return onVisible();
			}
			document.addEventListener(visibilityEventName, handleVisibilityChange, false);
			// extra event listeners for better behaviour
			document.addEventListener("focus", () => {
				handleVisibilityChange(true);
			}, false);

			document.addEventListener("blur", () => {
				handleVisibilityChange(false);
			}, false);
			window.addEventListener("focus", () => {
				handleVisibilityChange(true);
			}, false);
			window.addEventListener("blur", () => {
				handleVisibilityChange(false);
			}, false);
		},
		contrastingTextColor(bgColor: string): string {
			bgColor = (bgColor.charAt(0) === "#") ? bgColor.substring(1, 7) : bgColor;
			const r = parseInt(bgColor.substring(0, 2), 16); // hexToR
			const g = parseInt(bgColor.substring(2, 4), 16); // hexToG
			const b = parseInt(bgColor.substring(4, 6), 16); // hexToB
			const uicolors = [r / 255, g / 255, b / 255];
			const c = uicolors.map((col) => {
				if (col <= 0.03928) {
				return col / 12.92;
				}
				return Math.pow((col + 0.055) / 1.055, 2.4);
			});
			const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
			return (L > 0.179) ? "#222" : "#EEE";
		},
	},
	computed: {
		chronoTaskList(): Array<[string, string, number, Task]> {
			if (Object.keys(this.taskOverview).length < 0) return new Array();
			return (Object.keys(this.taskOverview) as any[]).reduce((agg: Array<[string, string, number, Task]>, boardID: string) => {
				const boardTasks = Object.keys(this.taskOverview[boardID]).reduce((agg2: Array<[string, string, number, Task]>, cat: string) => {
					return agg2.concat(this.taskOverview[boardID][cat].map((task: Task, i: number) => [boardID, cat, i, task]));
				}, new Array());
				return agg.concat(boardTasks);
			}, new Array()).sort(this.taskDueSort);
		},
		chronoTaskListKanbanBoards(): Record<string, Array<[string, string, number, Task]>> {
			return this.chronoTaskList.reduce((agg, chronoTask) => {
				const bid = chronoTask[0]
				if (!agg[bid]) agg[bid] = []
				agg[bid].push(chronoTask)
				return agg
			}, {} as Record<string, Array<[string, string, number, Task]>>)
		},
		calendarEvents(): any {
			return this.chronoTaskList.map(([boardID, cat, i, task]) => {
				return {
					id: [boardID, cat, i],
					extendedProps: {boardID, cat, i},
					title: task.name,
					date: task.dueDate,
				};
			});
		},
		axiosConfig(): any {
			return {
				headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
			};
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchTaskOverview().then(() => {
				this.addWindowBlurListeners();
			});
			if (this.$store.state.boardActiveView["overview"]) {
				this.activeView = this.$store.state.boardActiveView["overview"];
			}
		});
	},
});
</script>

<style lang="scss">
	.kanban-card {
		margin: 30px 10px 60px 10px;
		min-width: 180px; 
		max-width: 240px; 
		font-size: 76%;
		user-select: none;
	}
	.drag-handle {
		cursor: all-scroll;
	}
	/* Board View Selector */
	.v-input__prepend-outer {
		margin-right: 16px !important;
	}
	.v-select__selections input { display: none; }
	.v-select__selection { margin: auto !important; }
	.view-switcher .v-input__append-inner { width: 0px; }
	.category-selector .v-input__append-inner { width: 16px; }
	/* Tasklist Textarea */
	.tasklist-textarea:not(.subtask-textarea) {
		margin-top: 30px !important;
	}
	.tasklist-textarea textarea {
		margin: 1px 1px 1.2px 2px !important;
		max-height: 60px;
		overflow-y: auto !important;
	}
	.tasklist-textarea .v-text-field__details {
		display: none;
	}
	.tilted-card {
		transform: rotate(8deg);
	}
</style>
<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-next-button, .fc-prev-button {
	padding-bottom: 12px;
	padding-top: 0px;
	margin: 0 3px;
}
.fc-title {
	color: white;
}
</style>

<style scoped>
	#date-picker >>> .v-picker__actions {
		display: block;
	}
	#date-picker >>> .v-date-picker-table {
		height: auto;
	}
</style>

<style lang="scss">
	$toolbar-color: #555;
	.popper, .tooltip {
		position: absolute;
		z-index: 9999;
		background: $toolbar-color;
		color: white;
		width: 150px;
		border-radius: 3px;
		box-shadow: 0 0 2px rgba(0,0,0,0.5);
		padding: 10px;
		text-align: center;
		font-family: sans-serif;
		font-size: 90%;
	}
	.style5 .tooltip {
		background: #1E252B;
		color: #FFFFFF;
		max-width: 200px;
		width: auto;
		font-size: .8rem;
		padding: .5em 1em;
	}
	.popper .popper__arrow,
	.tooltip .tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
	}

	.tooltip .tooltip-arrow,
	.popper .popper__arrow {
		border-color: $toolbar-color;
	}
	.style5 .tooltip .tooltip-arrow {
		border-color: #1E252B;
	}
	.popper[x-placement^="top"],
	.tooltip[x-placement^="top"] {
		margin-bottom: 5px;
	}
	.popper[x-placement^="top"] .popper__arrow,
	.tooltip[x-placement^="top"] .tooltip-arrow {
		border-width: 5px 5px 0 5px;
		border-left-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		bottom: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}
	.popper[x-placement^="bottom"],
	.tooltip[x-placement^="bottom"] {
		margin-top: 5px;
	}
	.tooltip[x-placement^="bottom"] .tooltip-arrow,
	.popper[x-placement^="bottom"] .popper__arrow {
		border-width: 0 5px 5px 5px;
		border-left-color: transparent;
		border-right-color: transparent;
		border-top-color: transparent;
		top: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}
	.tooltip[x-placement^="right"],
	.popper[x-placement^="right"] {
		margin-left: 5px;
	}
	.popper[x-placement^="right"] .popper__arrow,
	.tooltip[x-placement^="right"] .tooltip-arrow {
		border-width: 5px 5px 5px 0;
		border-left-color: transparent;
		border-top-color: transparent;
		border-bottom-color: transparent;
		left: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}
	.popper[x-placement^="left"],
	.tooltip[x-placement^="left"] {
		margin-right: 5px;
	}
	.popper[x-placement^="left"] .popper__arrow,
	.tooltip[x-placement^="left"] .tooltip-arrow {
		border-width: 5px 0 5px 5px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		right: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}
</style>
