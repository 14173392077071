<template>
	<div>
		<headful title="Login" />
		<v-card style="max-width: 440px; margin: auto;">
			<v-toolbar color="primary">
				<h1 style="color: white; margin: auto">Doer Login</h1>
			</v-toolbar>
			<div style="padding: 40px 22px;">
				<v-text-field label="Email" v-model="email" outlined/>
				<v-text-field label="Password" v-model="password"
				outlined type="password"/>
				<v-btn @click="submitLogin" color="primary lighten-1">
					Submit
				</v-btn>
				<v-divider style="margin: 12px 0 20px 0;"/>
				<router-link to="/register">
					Register Account
				</router-link>
			</div>
		</v-card>
	</div>
</template>

<script lang="ts">
	import axios from "axios";
	import Vue from "vue";
	export default Vue.extend({
		data() {
			return {
				email: "",
				password: "",
			};
		},
		methods: {
			submitLogin() {
				const loginData = {
					username: this.email,
					password: this.password,
				};
				return axios.post(this.$store.state.apiURL + "/user/login", loginData)
				.then(resp => {
					this.$store.commit("setJWTToken", resp.data);
					this.$router.replace("/");
				})
				.catch(() => {
					this.$toast("Invalid email or password! Please try again.", {color: "red"});
					this.password = "";
				});
			},
		},
	});
</script>