<template>
	<div>
		<headful title="Registration" />
		<v-card style="max-width: 440px; margin: auto;">
			<v-toolbar color="primary">
				<h1 style="color: white; margin: auto">Doer Registration</h1>
			</v-toolbar>
			<div style="padding: 40px 22px;">
				<v-form ref="registerForm" v-model="formValid">
					<v-text-field label="Your Name" v-model="name" outlined :rules="nameRules"/>
					<v-text-field label="Your Email" v-model="email" outlined  :rules="emailRules"/>
					<v-text-field label="Your Password" v-model="password" outlined :rules="passwordRules" type="password"/>
					<v-text-field label="Confirm Password" v-model="passwordConfirm" outlined :rules="passwordConfirmRules" type="password"/>
				</v-form>
				<v-btn @click="$refs.registerForm.validate(); submitLogin();" color="primary lighten-1">
					Submit
				</v-btn>
			</div>
		</v-card>
	</div>
</template>

<script lang="ts">
	import axios from "axios";
	import Vue from "vue";
	export default Vue.extend({
		data() {
			return {
				formValid: false,
				name: "",
				nameRules: [
					(v: string) => !!v || "Name is required",
					(v: string) => (v && v.length > 1) || "Name must be more than 1 character",
				],
				email: "",
				emailRules: [
					(v: string) => !!v || "Email is required",
					(v: string) => /.+@.+\..+/.test(v) || "Invalid email address",
				],
				password: "",
				passwordRules: [
					(v: string) => /[a-z]+/.test(v) || "One lowercase letter required",
					(v: string) => /[A-Z]+/.test(v) || "One uppercase letter required",
					(v: string) => /.{8,}/.test(v) || "8 character minimum",
					(v: string) => /[0-9]+/.test(v) || "One number required",
				],
				passwordConfirm: "",
				passwordConfirmRules: [
					(v: string) => v === this.$data.password || "Passwords do not match",
				],
			};
		},
		methods: {
			submitLogin() {
				if (!this.formValid) return;
				const loginData = {
					fullName: this.name,
					email: this.email,
					password: this.password,
				};
				return axios.post(this.$store.state.apiURL + "/user/register", loginData)
				.then(resp => {
					this.$store.commit("setJWTToken", resp.data);
					this.$router.replace("/");
				})
				.catch((err) => {
					this.$toast(err.response.data.message, {color: "red"});
					//this.password = "";
				});
			},
		},
	});
</script>