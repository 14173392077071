<template>
	<div>
		<headful title="Taskboards" />
		<v-card style="max-width: 860px; margin: auto;">
			<v-toolbar color="primary">
				<h1 style="color: white; margin: auto">Taskboards</h1>
			</v-toolbar>
			<v-list two-line>
				<v-list-item v-for="(board, boardIndex) in boards" :key="boardIndex" ripple :draggable="false">
					<v-list-item-avatar color="teal">
						<span class="white--text headline">{{boardIndex+1}}</span>
					</v-list-item-avatar>
					<v-list-item-content style="padding: 0px;">
						<router-link :to="'/board/' + boards[boardIndex]._id"
						style="text-decoration: none; color: auto; padding: 10px;" :draggable="false">
							<v-list-item-title v-text="board.name" style="color: black" />
							<v-list-item-subtitle>
								Last Update: {{timeSince(board.lastUpdated)}}
							</v-list-item-subtitle>
						</router-link>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn fab small depressed @click="editBoardDialog=boardIndex">
							<v-icon color="grey lighten-1" style="font-size: 1.5em">fa-info</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<div v-show="fetchBoardsError === false && boards && boards.length < 1" style="padding: 20px 10px;">
					<h3>
						Click below to add your first Taskboard!
					</h3>
				</div>
				<div v-if="fetchBoardsError" style="padding: 20px 10px;">
					<h3>
						Error fetching Taskboard data!
						<br>
						<sub>Please try refreshing, and contact an <a href="mailto:jpdev.noreply@gmail.com">administrator</a> if the error persists!</sub>
					</h3>
				</div>
				<v-divider inset></v-divider>
				<v-list-item>
					<v-list-item-content>
						<v-btn color="primary" style="max-width: 80%; margin: auto;" @click="addBoardDialog=!addBoardDialog">
							Add New Board
						</v-btn>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		<v-dialog :value="addBoardDialog" persistent max-width="400">
			<v-card>
				<v-toolbar color="blue lighten-1">
					<span class="white--text headline">Add Taskboard</span>
				</v-toolbar>
				<br>
				<v-card-text>
					<v-text-field label="Board Name" required v-model="addBoardName"></v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="green darken-1" text @click="addBoardDialog = false">Cancel</v-btn>
					<v-btn color="green darken-1" text @click="addBoard">Add</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog :value="editBoardDialog !== undefined" persistent max-width="400">
			<v-card v-if="editBoardDialog !== undefined">
				<v-toolbar color="blue lighten-1">
					<span class="white--text headline">Edit Taskboard</span>
				</v-toolbar>
				<br>
				<v-card-text>
					<v-text-field label="Board Name" required v-model="boards[editBoardDialog].name"></v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<br>
				<v-btn color="red lighten-1" dark @click="deleteBoard(editBoardDialog)">Delete</v-btn>
				<br><br>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="green darken-1" text @click="editBoardDialog = undefined">Cancel</v-btn>
					<v-btn color="green darken-1" text @click="editBoard(editBoardDialog)">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template> 

<script lang="ts">
import Vue from "vue";
import moment from "moment";
import { Task, CategoryMetadata, TaskBoard } from "../../../src/task/taskboard.model";
import axios from "axios";
import mongoose from "mongoose";

export default Vue.extend({
	data() {
		return {
			boards: [] as TaskBoard[],
			fetchBoardsError: undefined as undefined | boolean,
			addBoardDialog: false,
			editBoardDialog: undefined as number | undefined,
			addBoardName: "",
		};
	},
	methods: {
		fetchBoards() {
			axios.get(this.$store.state.apiURL + "/boards", this.axiosConfig)
			.then(resp => {
				this.boards = resp.data;
				this.fetchBoardsError = false;
			}).catch((err) => {
				if (err.response && err.response.status === 401) {
					this.$router.push("/login");
				} else {
					this.fetchBoardsError = true;
					this.$toast("Could not fetch board data from server: " + err);
				}
			});
		},
		addBoard() {
			const newBoard = {
				_id: new mongoose.Types.ObjectId().toString(),
				name: this.addBoardName,
				lastUpdated: new Date(),
				activeTasks: {"Uncategorized": []} as {[cat: string]: Task[]},
				completedTasks: {} as {[cat: string]: Task[]},
				catMetadata: {catOrder: ["Uncategorized"], catColors: {}} as CategoryMetadata,
			} as TaskBoard;
			axios.post(this.$store.state.apiURL + "/boards/add", newBoard, this.axiosConfig)
			.then(resp => {
				this.boards.unshift(newBoard);
				this.addBoardName = "";
				this.addBoardDialog = false;
			}).catch((err) => {
				if (err.response.status === 401) {
					this.$router.push("/login");
				} else {
					this.$toast("Could not add board from server: " + err.response);
				}
			});
		},
		editBoard(boardIndex: number) {
			const updatedBoard = this.boards[boardIndex];
			updatedBoard.lastUpdated = new Date();
			axios.post(this.$store.state.apiURL + "/boards/edit", updatedBoard, this.axiosConfig)
			.then(resp => {
				this.editBoardDialog = undefined;
			});
		},
		deleteBoard(boardIndex: number) {
			const confirmDelete = confirm("Are you sure you want to delete this board?");
			if (!confirmDelete) return;
			axios.delete(this.$store.state.apiURL + "/boards/" + this.boards[boardIndex]._id, this.axiosConfig)
			.then(resp => {
				this.editBoardDialog = undefined;
				this.boards.splice(boardIndex, 1);
			});
		},
		timeSince(date: Date): string {
			return moment(date).fromNow();
		},
	},
	computed: {
		axiosConfig(): any {
			return {
				headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
			};
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchBoards();
		});
	},
});
</script>