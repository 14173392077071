<template>
	<div>
		<headful title="My Habits" />
		<v-card style="max-width: 860px; margin: 30px auto">
			<v-toolbar color="primary" dark>
				<v-layout align-center justify-center>
					<h2 style="color: white; margin-left: 4px;">Habit Tracker</h2>
				</v-layout>
			</v-toolbar>
			<div style="padding: 40px;">
				<div v-if="board.habits && board.habits.length > 0">
					<draggable v-model="board.habits" handle=".drag-handle" @end="saveBoard()">
						<div style="display: flex;"  v-for="(habit, habitIndex) in board.habits" :key="habitIndex">
							<div style="width: 40%; min-width: 40%; margin-right: 16px;">
								<v-textarea :placeholder="'Habit #' + (habitIndex + 1)" 
								v-model="habit.name" 
								@input="saveBoardTimeout" @blur="saveBoard"
								outlined class="habitlist-textarea" auto-grow rows="3" />
								<div class="drag-handle" style="cursor: move; float: left; background-color: #f5f5f5; border-radius: 20px; padding: 8px;">
									<v-icon style="font-size: 110%;">fa-arrows-alt</v-icon>
								</div>
								<v-btn depressed @click="openHabitDialog(habitIndex)" style="float: right; transform: scale(0.8);">
									<v-icon>fa-cog</v-icon>
								</v-btn>
							</div>
							<div style="min-width: 58%; margin: 10px; padding: 10px 16px; overflow-x: auto; border: 1px dotted #aaa;">
								<div style="display: flex; margin-bottom: 16px;" class="habit-checklist">
									<div v-for="(day, dayIndex) in habit.habitCompletion.slice(0,100)" :key="dayIndex" style="margin: 4px;">
										<span style="white-space: nowrap;">{{day[0]}}</span>
										<div style="display: flex; flex-wrap: wrap; flex-direction: column;">
											<div v-for="(complete, completionIndex) in day[1]"  :key="completionIndex" style="width: auto; border: 1px solid black;">
												<div class="check">
													<input :id="'check-' + habitIndex + '-' + dayIndex + '-' + completionIndex" type="checkbox" v-model="board.habits[habitIndex].habitCompletion[dayIndex][1][completionIndex]" @change="saveBoardTimeout()"/>
													<label :for="'check-' + habitIndex + '-' + dayIndex + '-' + completionIndex">
														<div class="box"><i class="fa fa-check"></i></div>
													</label>
												</div>
											</div>
										</div>
									</div>
									<div style="min-width: 20px; height: 1px;"></div>
								</div>
							</div>
						</div>
					</draggable>
				</div>
				<div v-else>
					<h2>No habits to display, why don't you add one!</h2>
				</div>
				<v-divider style="margin: 18px;"/>
				<v-btn fab small color="primary" @click="addHabit()" v-if="board.habits && board.habits.length > 0" class="elevation-2">
					<v-icon>fa-plus</v-icon>
				</v-btn>
				<v-btn color="primary" @click="addHabit()" v-else>
					Add Habit
				</v-btn>
			</div>
		</v-card>
		<v-dialog :value="editHabitDialog !== undefined" max-width="680" class="editHabitDialog" persistent>
			<v-card v-if="editHabitDialog !== undefined">
				<v-toolbar color="blue lighten-1">
					<span class="white--text headline">Edit Habit</span>
					<div class="flex-grow-1"></div>

					<v-btn icon @click="closeHabitDialog(save=true);">
						<v-icon>fa-times</v-icon>
					</v-btn>

				</v-toolbar>
				<div style="max-height: 78vh; overflow: auto; padding: 10px 20px 30px 20px;">
					<v-textarea placeholder="Write Your Habit Here!" v-model="board.habits[editHabitDialog].name"
					outlined class="habitlist-textarea" auto-grow rows="3"
					@input="saveBoardTimeout" @blur="saveBoard"/>
					<v-divider style="margin: 10px 0;"/>
					<b>Repeat </b> 
					<input type="number" style="width: 40px; border: 1px dotted #aaa; border-radius: 4px; text-align: center; margin-left: 8px;" v-model="habitTimesNum" :min="1" :max="99"/>
					<b> Time<span v-show="habitTimesNum > 1">s</span> Every:</b>
					<input type="number" style="width: 40px; border: 1px dotted #aaa; border-radius: 4px; text-align: center; margin-left: 8px;" v-model="habitIntervalNum" :min="1" :max="365 * 20"/>
					<v-select :items="['day', 'week'].map((x) => {return {value: (x + 's'), text: (x + ((habitIntervalNum > 1) ? 's' : ''))};})" style="display: inline-block; min-width: 80px; margin-left: 8px;" v-model="habitIntervalSelector"/>
					<div v-show="habitIntervalSelector == 'weeks' && board.habits[editHabitDialog].repeat.everyNWeeks">
						<b>Repeat On:</b>
						<br>
						<div style="display: flex; justify-content: center; margin-top: 4px;">
							<div v-for="(day, dayIndex) in dow" :key="dayIndex" class="dow-checkbox">
								<input type="checkbox" v-model="habitDoW" :value="dayIndex" :id="'dowcheckox-' + dayIndex"/>
								<label :for="'dowcheckox-' + dayIndex">
									<div class="box">{{day[0].toUpperCase()}}</div>
								</label>
							</div>
						</div>
					</div>
					<div style="margin-top: 10px; font-style: italic;">Note: Any changes to interval after initial creation will only affect future dates!</div>
					<div style="width: 80%; min-width: 280px; margin: 20px auto 0 auto; border: 1px dotted #aaa; border-radius: 6px; padding: 6px 8px; font-size: 80%;" v-show="board.habits[editHabitDialog].name.length < 1">
						<b>For Example:</b>
						<v-divider style="width: 90%; margin: 5px auto;" />
						- Brush + Floss (repeat 3 times every 1 day)<br>
						- Workout (repeat 3 times every 1 week)<br>
						- Practice (repeat 1 time every 1 week on MWF)
					</div>
				</div>
				<v-card-actions style="border-top: 1px dotted #aaa; padding: 18px 16px;">
					<v-btn color="red" @click="deleteHabit(editHabitDialog)">
						<v-icon>fa-trash</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="closeHabitDialog(save=false)">
						Cancel
					</v-btn>
					<v-btn color="primary" @click="closeHabitDialog(save=true)">
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import draggable from "vuedraggable";
	import axios from "axios";
	import mongoose from "mongoose";
	import moment from "moment";
	import { HabitBoard, Habit, TaskRepeat } from "../../../src/habit/habit.model";

	export default Vue.extend({
		components: {
			draggable,
		},
		data() {
			return {
				windowFocused: true,
				board: {} as HabitBoard,
				lastSavedBoard: "" as string,
				saveTimeout: undefined as number | undefined,
				refreshTimeoutInterval: 5, //time between auto-refreshes (in minutes)
				refreshTimeout: undefined as any,
				editHabitDialog: undefined as number | undefined,
				habitTimesNum: undefined as number | undefined,
				habitIntervalNum: undefined as number | undefined,
				habitIntervalSelector: undefined as string | undefined,
				habitDoW: [] as number[],
				dow: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			};
		},
		methods: {
			openHabitDialog(habitIndex: number) {
				const repeat = this.board.habits[habitIndex].repeat;
				this.habitTimesNum = repeat.timesPerDay || 1;
				this.habitIntervalSelector = (repeat.everyNDays && repeat.everyNDays > 0) ? "days" : ((repeat.everyNWeeks && repeat.everyNWeeks[0] > 0 ) ? "weeks" : "days");
				this.habitIntervalNum = (repeat.everyNDays && repeat.everyNDays > 0) ? repeat.everyNDays : ((repeat.everyNWeeks && repeat.everyNWeeks[0] > 0 ) ? repeat.everyNWeeks[0] : 1);
				this.editHabitDialog = habitIndex;
				this.habitDoW = (repeat.everyNWeeks && repeat.everyNWeeks[1]) ? repeat.everyNWeeks[1] : [];
			},
			closeHabitDialog(save = true) {
				if (this.editHabitDialog === undefined) return;
				const newRepeat = {
					timesPerDay: Math.max(this.habitTimesNum!, 1),
					everyNDays: this.habitIntervalSelector === "days" ? Math.max(this.habitIntervalNum || 1, 1) : 0,
					everyNWeeks: this.habitIntervalSelector === "weeks" ? [Math.max(this.habitIntervalNum || 1, 1), this.habitDoW] : [0, this.habitDoW],
				} as TaskRepeat;
				this.$set(this.board.habits[this.editHabitDialog], "repeat", newRepeat);
				this.editHabitDialog = undefined;
				this.habitTimesNum = undefined;
				this.habitIntervalSelector = undefined;
				this.habitIntervalNum = undefined;
				this.updateHabitCompletion(save);
			},
			addHabit(habitName: string | undefined) {
				const newHabit: Habit = {
					_id: new mongoose.Types.ObjectId().toString(),
					name: habitName || "",
					habitCompletion: [],
					repeat: { timesPerDay: 1, everyNDays: 1, everyNWeeks: [0, []] } as TaskRepeat,
				};
				this.board.habits.push(newHabit);
				this.openHabitDialog(this.board.habits.length - 1);
			},
			deleteHabit(habitIndex: number) {
				if (!confirm("Are you sure you want to delete this habit?")) return;
				this.closeHabitDialog();
				this.board.habits.splice(habitIndex, 1);
			},
			saveBoard(): Promise<any> {
				// Clear currently running timeouts for board save
				clearTimeout(this.saveTimeout);
				// Only attempt to save data to server if something has changed since the last save
				if (this.lastSavedBoard !== JSON.stringify(this.board)) {
					// Delete empty task entries
					/*if (this.editHabitDialog === undefined) {
						this.$set(this.board, "habits", this.board.habits.filter((habit) => {
								return habit.name !== "" || habit.description !== "";
						}));
					}*/
					this.board.lastUpdated = new Date();
					// Send save data to server!
					return axios.post(this.$store.state.apiURL + "/habit", this.board, this.axiosConfig)
					.then(resp => {
						this.$toast("Saved habits!");
						// Reset last saved board if save successful
						this.lastSavedBoard = JSON.stringify(this.board);
					});
				}
				return Promise.resolve();
			},
			saveBoardTimeout() {
				clearTimeout(this.saveTimeout);
				this.saveTimeout = window.setTimeout(() => {
					this.saveBoard();
				}, 800);
			},
			updateHabitCompletion(save = false) {
				let changedCount = 0;
				if (!this.board.habits) return
				const newHabits = this.board.habits.map((habit, habitIndex) => {
					const lastEntryDate: moment.Moment = habit.habitCompletion.length > 0 ? (habit.habitCompletion[0][0].indexOf("-") !== -1 ? moment(habit.habitCompletion[0][0].split("-")[1], "MM/DD/YY") : moment(habit.habitCompletion[0][0], "MM/DD/YY")) : moment().startOf("week").subtract("2", "weeks");
					const daysSinceLastEntry: number = moment().diff(lastEntryDate, "days");
					const lastEntryDoW = lastEntryDate.day();
					const lastEntryDaysUntilNextWeek =  7 - lastEntryDoW;
					if (daysSinceLastEntry >= 1) {
						Array(daysSinceLastEntry).fill(0).map((_, i) => i).filter((_, dayIndex) => {
							if (habit.repeat.everyNDays >= 1) {
								return ((dayIndex + 1) % habit.repeat.everyNDays) === 0;
							} else if (habit.repeat.everyNWeeks[0] >= 1) {
								const dow = (lastEntryDoW + dayIndex + 1) % (7 * habit.repeat.everyNWeeks[0]);
								const correctDoW: boolean = habit.repeat.everyNWeeks[1].includes(dow);
								const currentWeek: boolean = lastEntryDaysUntilNextWeek > dayIndex;
								const matchingWeekInterval: boolean = (dayIndex - lastEntryDaysUntilNextWeek > 0 && ((dayIndex - lastEntryDaysUntilNextWeek) / 7) > (habit.repeat.everyNWeeks[0] - 1) );
								return (habit.repeat.everyNWeeks[1].length > 0) ? (correctDoW && (currentWeek || matchingWeekInterval)) : dow === 0;
							}
							return true;
						}).forEach((dayIndex) => {
							changedCount++;
							const completionArray = Array(habit.repeat.timesPerDay).fill(false);
							if (habit.repeat.everyNDays === 0 && habit.repeat.everyNWeeks[0] >= 1 && habit.repeat.everyNWeeks[1].length === 0) {
								const weekStart = lastEntryDate.clone().add(dayIndex + 1, "days").startOf("week");
								const weekEnd = weekStart.clone().add(6, "days");
								habit.habitCompletion.unshift([weekStart.format("MM/DD-") + weekEnd.format("MM/DD/YY"), completionArray]);
							} else {
								habit.habitCompletion.unshift([lastEntryDate.clone().add(dayIndex + 1, "days").format("MM/DD/YY"), completionArray]);
							}
						});
					}
					return habit;
				});
				if (changedCount > 0 || save) {
					this.saveBoard();
				}
				// this.$set(this.board, "habits", newHabits);
			},
			fetchBoard() {
				return axios.get(this.$store.state.apiURL + "/habit/", this.axiosConfig)
				.then(resp => {
					this.board = resp.data;
				})
				.catch((e) => {
					this.$router.push("/");
				}).then(() => {
					this.updateHabitCompletion();
					this.lastSavedBoard = JSON.stringify(this.board);
					//TODO: is allocating a new timeout every time inefficient??
					clearInterval(this.refreshTimeout);
					this.refreshTimeout = setTimeout(() => {
						this.fetchBoard();
					}, this.refreshTimeoutInterval  * 60 * 1000);
				});
			},
			addWindowBlurListeners() {
				const browserPrefixes = ["moz", "ms", "o", "webkit"];
				// get the correct attribute name
				function getHiddenPropertyName(prefix: string | undefined): string {
					return (prefix ? prefix + "Hidden" : "hidden");
				}

				// get the correct event name
				function getVisibilityEvent(prefix: string | undefined) {
					return (prefix ? prefix : "") + "visibilitychange";
				}

				// get current browser vendor prefix
				function getBrowserPrefix() {
					for (const prefix of browserPrefixes) {
						if (getHiddenPropertyName(prefix) in document) {
							// return vendor prefix
							return prefix;
						}
					}

					// no vendor prefix needed
					return undefined;
				}

				// bind and handle events
				const browserPrefix = getBrowserPrefix();
				const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
				const visibilityEventName = getVisibilityEvent(browserPrefix);

				const onVisible = () => {
					// prevent double execution
					if (this.windowFocused) {
						return;
					}
					// change flag value
					this.windowFocused = true;
					// Update board data
					return this.fetchBoard();
				};

				const onHidden = () => {
					// prevent double execution
					if (!this.windowFocused) {
						return;
					}
					// change flag value
					this.windowFocused = false;
					// Save board data
					this.saveBoard();
				};

				function handleVisibilityChange(forcedFlag: boolean | Event) {
				// forcedFlag is a boolean when this event handler is triggered by a
				// focus or blur eventotherwise it's an Event object
					if (typeof forcedFlag === "boolean") {
						if (forcedFlag) {
							return onVisible();
						}
						return onHidden();
					}
					if ((document as any)[hiddenPropertyName]) {
						return onHidden();
					}
					return onVisible();
				}
				document.addEventListener(visibilityEventName, handleVisibilityChange, false);
				// extra event listeners for better behaviour
				document.addEventListener("focus", () => {
					handleVisibilityChange(true);
				}, false);

				document.addEventListener("blur", () => {
					handleVisibilityChange(false);
				}, false);
				window.addEventListener("focus", () => {
					handleVisibilityChange(true);
				}, false);
				window.addEventListener("blur", () => {
					handleVisibilityChange(false);
				}, false);
			},
		},
		computed: {
			axiosConfig(): any {
				return {
					headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
				};
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.fetchBoard().then(() => {
					this.addWindowBlurListeners();
				});
			});
		},
	});
</script>

<style>
	.editHabitDialog .v-select select {
		text-align: center;
		text-align-last: center;
	}
	.habitlist-textarea {
		margin-top: 20px !important;
	}
	.habitlist-textarea textarea {
		margin: 1px 1px 1.2px 2px !important;
		max-height: 120px;
		overflow-y: auto !important;
	}
	.habitlist-textarea .v-text-field__details {
		display: none;
	}
</style>

<style scoped>
	.dow-checkbox input {
		display: none;
	}
	.dow-checkbox input {
		display: none;
	}
	.dow-checkbox input:checked + label .box {
		-webkit-animation: animOnTransform 1s 1 forwards;
				animation: animOnTransform 1s 1 forwards;
		background: rgba(0, 0, 0, 0.5);
		font-weight: bold;
		color: gold;
	}
	.dow-checkbox input:checked + label .box i {
		-webkit-transform: translate(-50%, -50%) scale(1);
				transform: translate(-50%, -50%) scale(1);
		transition-duration: 200ms;
		transition-delay: 400ms;
		opacity: 1;
		color: gold;
	}
	.dow-checkbox label {
		min-width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		min-height: 40px;
		cursor: pointer;
	}
	.dow-checkbox label .box {
		background: rgba(0, 0, 0, 0.3);
		border-radius: 30px;
		position: relative;
		width: 30px;
		height: 30px;
		padding-top: 4px;
		transition: background 300ms ease;
	}
	.dow-checkbox label .box:hover {
		background: rgba(0, 0, 0, 0.5);
	}
	.dow-checkbox label .box i {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 20px;
		display: inline-block;
		opacity: 0;
		pointer-events: none;
		transition: all 0.2s ease-in-out;
		transition-delay: 200ms;
		-webkit-transform: translate(-50%, -50%) scale(4);
				transform: translate(-50%, -50%) scale(4);
	}
</style>

<style scoped>
.check input {
  display: none;
}
.check input:checked + label .box {
  -webkit-animation: animOnTransform 1s 1 forwards;
          animation: animOnTransform 1s 1 forwards;
  background: rgba(0, 0, 0, 0.5);
}
.check input:checked + label .box i {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  transition-duration: 200ms;
  transition-delay: 400ms;
  opacity: 1;
  color: gold;
}
.check label {
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: 40px;
  cursor: pointer;
}
.check label .box {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  width: 30px;
  height: 30px;
  transition: background 300ms ease;
  padding-top: 4px;
}
.check label .box:hover {
  background: rgba(0, 0, 0, 0.5);
}
.check label .box i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  display: inline-block;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transition-delay: 200ms;
  -webkit-transform: translate(-50%, -50%) scale(4);
          transform: translate(-50%, -50%) scale(4);
}

@-webkit-keyframes animOn {
  40% {
    height: 20px;
    width: 100px;
  }
  50% {
    height: 60px;
    width: 30px;
  }
  60% {
    height: 40px;
    width: 70px;
  }
  70% {
    height: 55px;
    width: 45px;
  }
  100% {
    height: 50px;
    width: 50px;
  }
}

@keyframes animOn {
  40% {
    height: 20px;
    width: 100px;
  }
  50% {
    height: 60px;
    width: 30px;
  }
  60% {
    height: 40px;
    width: 70px;
  }
  70% {
    height: 55px;
    width: 45px;
  }
  100% {
    height: 50px;
    width: 50px;
  }
}
@-webkit-keyframes animOnTransform {
  40% {
    -webkit-transform: scale(1.5, 0.5);
            transform: scale(1.5, 0.5);
  }
  50% {
    -webkit-transform: scale(0.5, 1.5);
            transform: scale(0.5, 1.5);
  }
  60% {
    -webkit-transform: scale(1.3, 0.6);
            transform: scale(1.3, 0.6);
  }
  70% {
    -webkit-transform: scale(0.8, 1.2);
            transform: scale(0.8, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes animOnTransform {
  40% {
    -webkit-transform: scale(1.5, 0.5);
            transform: scale(1.5, 0.5);
  }
  50% {
    -webkit-transform: scale(0.5, 1.5);
            transform: scale(0.5, 1.5);
  }
  60% {
    -webkit-transform: scale(1.3, 0.6);
            transform: scale(1.3, 0.6);
  }
  70% {
    -webkit-transform: scale(0.8, 1.2);
            transform: scale(0.8, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
/* end of custom code */

</style>