<template>
	<div>
		<headful title="Taskboards" />
		<v-card style="max-width: 860px; margin: auto;">
			<v-toolbar color="primary">
				<h1 style="color: white; margin: auto">Taskboards</h1>
			</v-toolbar>
			<br>
			<v-card-text>
				<h3>External Calendar Access</h3>
				<v-text-field readonly label="ical URL" v-model="icalURL" @click="copyICalURL" ref="icalURL"
				style="max-width: 340px; margin: auto;"/>
				<v-btn @click="apikeyRegen">
					Regenerate API Key
				</v-btn>
			</v-card-text>
			<br><br>
		</v-card>
	</div>
</template>

<script lang="ts">
	import Vue from "vue";
	import axios from "axios";
	export default Vue.extend({
		methods: {
			apikeyRegen() {
				axios.get(this.$store.state.apiURL + "/user/regenkey", this.axiosConfig)
				.then(resp => {
					this.$store.commit("setJWTToken", resp.data);
					this.$toast("Regenerated API key!");
				}).catch((err) => {
					if (err.response && err.response.status === 401) {
						this.$router.push("/login");
					} else {
						this.$toast("Could not update API key: " + err);
					}
				});
			},
			copyICalURL() {
				const icalElem = this.$refs.icalURL as Vue;
				icalElem.$el.querySelector("input")!.select();
				document.execCommand("copy");
				this.$toast("Calendar export URL copied to clipboard!");
			},
		},
		computed: {
			axiosConfig(): any {
				return {
					headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
				};
			},
			icalURL(): string {
				const baseURL = /https?:\/\/.*/.test(this.$store.state.apiURL) ? this.$store.state.apiURL : (window.location.origin + this.$store.state.apiURL);
				return baseURL + "/ical/" + this.$store.state.jwtClaims._id + "/" + this.$store.state.jwtClaims.apikey;
			},
		},
	});
</script>