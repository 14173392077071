import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Boards from "./views/Boards.vue";
import TaskBoard from "./views/TaskBoard.vue";
import TaskOverview from "./views/TaskOverview.vue";
import Habits from "./views/Habits.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Logout from "./views/Logout.vue";
import UserSettings from "./views/UserSettings.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "BoardList",
			component: Boards,
		},
		{
			path: "/habits",
			name: "Habits",
			component: Habits,
		},
		{
			path: "/board/overview",
			name: "TaskOverview",
			component: TaskOverview,
			props: true,
		},
		{
			path: "/board/:boardID/:urlCat?/:urlTaskIndex?",
			name: "TaskBoard",
			component: TaskBoard,
			props: true,
		},
		{
			path: "/login",
			name: "Login",
			component: Login,
		},
		{
			path: "/register",
			name: "Register",
			component: Register,
		},
		{
			path: "/logout",
			name: "Logout",
			component: Logout,
		},
		{
			path: "/settings",
			name: "UserSettings",
			component: UserSettings,
		},
	],
});
