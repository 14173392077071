<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" app clipped mobile-break-point="600" width="180" touchless>
			<v-list shaped>
				<v-list-item link v-for="(navEntry, navIndex) in navList" :key="navIndex"
				:to="navEntry.url" @click="drawer=false;" :draggable="false">
					<v-list-item-icon v-if="navEntry.icon" style="margin-right: 0px;">
						<v-icon>{{navEntry.icon || "grip-lines"}}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title style="font-size: 120%;">
							{{navEntry.name}}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list v-if="$store.state.jwtToken !== undefined && boardList.length > 0">
				<v-divider style="margin-top: 2px;"/>
				<div style="color: grey; font-size: 72%; margin-bottom: 12px;">
					Recent Boards
				</div>
				<v-list-item v-for="board in boardList" :key="board[0]" :draggable="false" :to="'/board/' + board[0]">
					<v-list-item-content>
						{{board[1]}}
					</v-list-item-content>
				</v-list-item>
			</v-list>

		</v-navigation-drawer>

		<v-app-bar app clipped-left>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer">
				<v-icon>fa-bars</v-icon>
			</v-app-bar-nav-icon>
			<v-btn depressed to="/" style="color: green; text-decoration: none; margin-left: 14px;">
				<v-toolbar-title style="text-transform: none;">
					Doer
				</v-toolbar-title>
			</v-btn>
			<v-btn depressed to="/board/overview" style="color: black; text-decoration: none; margin-left: 14px;">
				<v-toolbar-title style="text-transform: none;">
					Overview
				</v-toolbar-title>
			</v-btn>
			<v-btn depressed to="/habits" style="color: black; text-decoration: none; margin-left: 14px;">
				<v-toolbar-title style="text-transform: none;">
					Habits
				</v-toolbar-title>
			</v-btn>
		</v-app-bar>

		<v-content>
			<v-container fluid>
				<router-view/>
			</v-container>
		</v-content>

		<v-footer app>
			<span>Doer &copy; 2019</span>
		</v-footer>
	</v-app>
</template>

<script lang="ts">
import Vue from "vue";
import {polyfill} from "mobile-drag-drop";
import axios from "axios";

// optional import of scroll behaviour
import {scrollBehaviourDragImageTranslateOverride} from "mobile-drag-drop/scroll-behaviour";

export default Vue.extend({
	data() {
		return{
			drawer: false,
			boardList: [] as [string, string][],
		};
	},
	computed: {
		axiosConfig(): any {
			return {
				headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
			};
		},
		navList(): any {
			return [
				{
					name: "Home",
					icon: "fa-home",
					url: "/",
				},
				{
					name: "Overview",
					icon: "fa-check",
					url: "/board/overview",
				},
				{
					name: "Habits",
					icon: "fa-redo-alt",
					url: "/habits",
				},
				{
					name: "Settings",
					icon: "fa-cog",
					url: "/settings",
				},
				{
					name: this.$store.state.jwtToken !== undefined ? "Logout" : "Login",
					icon: "fa-key",
					url: this.$store.state.jwtToken !== undefined ? "/logout" : "/login",
				},
			];
		},
	},
	watch: {
		"$route"(to, from) {
			const matches = to.path.match(/\/board\/(.*)/);
			if (matches && matches.length === 2 && matches[1] !== "overview") {
				this.updateBoardList(matches[1]);
			}
		},
	},
	methods: {
		updateBoardList(id: string): void {
			const entryIndex = this.boardList.findIndex(([fid, fname]: [string, string]) => id === fid);
			if (entryIndex !== -1) {
				const boardName = this.boardList[entryIndex][1];
				this.boardList.splice(entryIndex, 1);
				this.boardList.unshift([id, boardName]);
				//this.boardList = this.boardList.slice(0, 6);
			}
		},
		fetchBoardList(): Promise<void> {
			return axios.get(this.$store.state.apiURL + "/boards/boardlist", this.axiosConfig)
			.then(resp => {
				this.boardList = resp.data;
			}).catch((err) => {
				if (err.response && err.response.status != 401) {
					this.$toast("Could not fetch board data from server: " + err);
				}
			});
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchBoardList();
			polyfill({
				dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
			});
			window.addEventListener( "touchmove", () => { return; });
			window.addEventListener("dragenter", (event) => {
				event.preventDefault();
			});
			if (["127.0.0.1:8080", "localhost:8080"].includes(window.location.host)) {
				this.$store.commit("setLocalAPI");
			}
		});
	},
});
</script>

<style lang="scss">
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}
	#nav {
		padding: 30px;
		a {
			font-weight: bold;
			color: #2c3e50;
			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
	@media screen and (max-height: 800px) {
		.v-footer {
			visibility: hidden;
		}
	}
	/* Scrollbar */
	$border-rad: 3px;
	::-webkit-scrollbar {
		border-radius: 1px $border-rad $border-rad  1px;
		width: 6px;
		padding: 4px;
		margin-right: 4px !important;
		background-color: #fff;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 1px $border-rad $border-rad  1px;
		box-shadow: inset 0 0 6px rgba(0,0,0,.14);
		background-color: #6087af;
	}

	::-webkit-scrollbar-track {
		border-radius: 1px $border-rad $border-rad  1px;
		box-shadow: inset 0 0 6px rgba(0,0,0,0.14);
		background-color: #fff;
	}
</style>
