<template>
	<div>
		<headful :title="'Tasks - ' + board.name" />
		<v-card :style="{'max-width': (activeView == 'Kanban' ? 'unset' : '860px'), 'margin': 'auto'}">
			<v-toolbar color="primary" dark>
				<h2 style="color: white; margin-left: 4px;">{{board.name}}</h2>
				<v-layout align-center justify-end>
				<v-flex shrink style="min-width: 150px;">
					<v-select v-model="activeView" :items="['Tasks', 'Calendar', 'Kanban']"
					background-color="teal darken-2" solo class="text-right view-switcher" @input="saveBoardActiveView"
					style="font-weight: bold; transform: scale(0.8); margin-top: 20px; text-align: right;"/>
				</v-flex>
				</v-layout>
			</v-toolbar>
			<div v-if="['Calendar', 'Tasks'].includes(activeView)">
				<br>
				<div v-if="activeView == 'Calendar'" style="padding: 0 20px;">
					<FullCalendar defaultView="dayGridMonth" :plugins="calendarPlugins" 
					:events="calendarEvents" :header="calConfig.header"
					@eventClick="calendarEventClick" :eventRender="calendarEventRender"/>
				</div>
				<div v-if="activeView == 'Tasks'">
					<v-list dense two-line subheader>
						<div>
							<v-list-item v-for="([cat, taskIndex, task], chronoIndex) in chronoTaskList" :key="task._id"
							ripple style="height: 100px; padding-bottom: 10px; overflow: hidden;" :id="'tasklistentry-'+cat+'-'+taskIndex">
								<v-list-item-avatar color="teal" style="transform: scale(0.75);">
									<span class="white--text headline">{{chronoIndex+1}}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title>
										<v-textarea :placeholder="'Task #' + (chronoIndex + 1)" 
										v-model="board.activeTasks[cat][taskIndex].name" 
										@input="saveBoardTimeout" @blur="saveBoard"
										outlined class="tasklist-textarea" auto-grow rows="1" />
										<div class="tasklist-textarea-printable">
											{{board.activeTasks[cat][taskIndex].name}}
										</div>
									</v-list-item-title>
									<v-list-item-subtitle style="text-align: left; margin-top: -4px; margin-left: 4px; margin-bottom: 10px; padding-bottom: 4px;">
										<span v-html="cat" @click="editTaskDialog=[cat, taskIndex]"
										:style="{'color': contrastingTextColor(board.catMetadata.catColors[cat] || '#757575'), 'background-color': board.catMetadata.catColors[cat] || '#bbb', 'font-size': '90%', 'padding': '2px 8px', 'text-align': 'center', 'border-radius': '4px', 'cursor': 'pointer'}">
										</span>
										&nbsp;&nbsp;|&nbsp;&nbsp;
										<span v-if="board.activeTasks[cat][taskIndex].vagueDueDate != undefined || board.activeTasks[cat][taskIndex].dueDate != undefined"
										@click="setDueDate(cat, taskIndex)"
										:style="{'color': taskOverdue(task) ? '#da3434' : 'black', 'cursor': 'pointer'}">
											Due: {{board.activeTasks[cat][taskIndex].vagueDueDate || toHumanTime(board.activeTasks[cat][taskIndex].dueDate)}}
										</span>
										<span v-else @click="setDueDate(cat, taskIndex)" style="cursor: pointer;">
											Due: ???
										</span>
										<span v-if="(board.activeTasks[cat][taskIndex].activeSubtasks.length + board.activeTasks[cat][taskIndex].completedSubtasks.length) > 0"
										@click="editTaskDialog=[cat, taskIndex]" style="cursor: pointer;">
											&nbsp;&nbsp;|&nbsp;&nbsp;
											Subtasks: <sup>{{board.activeTasks[cat][taskIndex].completedSubtasks.length}}</sup>&frasl;<sub>{{board.activeTasks[cat][taskIndex].activeSubtasks.length + board.activeTasks[cat][taskIndex].completedSubtasks.length}}</sub>
										</span>
									</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action style="transform: scale(0.8); margin-top: 0px;" class="task-list-action-btns">
									<v-btn fab small depressed color="green lighten-1"
									@click="completeTask(cat, taskIndex)"
									style="margin-bottom: 10px;">
										<v-icon style="font-size: 1.5em">fa-check</v-icon>
									</v-btn>
									<v-btn fab small depressed @click="editTaskDialog=[cat, taskIndex]"
									color="grey lighten-2">
										<v-icon style="font-size: 1.5em"
										:color=" board.activeTasks[cat][taskIndex].description && board.activeTasks[cat][taskIndex].description.length > 0 ? 'primary darken-2' : 'grey'" >>
											fa-info
										</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</div>
					</v-list>
					<div v-if="board.activeTasks && board.activeTasks.length < 1" style="padding: 20px 10px;">
						<h3>
							No active tasks, click below to add one!
						</h3>
					</div>
					<v-divider style="margin: 18px;"/>
					<div class="task-add-btn">
						<v-btn fab small color="primary" @click="addTask()" v-if="board.activeTasks && board.activeTasks.length > 0">
							<v-icon>fa-plus</v-icon>
						</v-btn>
						<v-btn color="primary" @click="addTask()" v-else>
							Add Task
						</v-btn>
					</div>
				</div>
				<v-divider style="margin-top: 18px;"/>
				<div class="completed-task-list">
					<v-expand-transition>
						<div v-show="showCompletedTasks && completedTaskList.length > 0">
							<h3 style="margin-top: 12px; margin-bottom: -12px;">Completed Tasks</h3>
							<v-list dense>
								<v-list-item v-for="([cat, taskIndex, task], completedIndex) in completedTaskList" :key="completedIndex"
								ripple style="height: 90px; transform: scale(0.85);">
									<v-list-item-avatar color="teal" style="transform: scale(0.75);">
										<span class="white--text headline">{{completedIndex+1}}</span>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>
											<v-text-field v-model="board.completedTasks[cat][taskIndex].name" 
											outlined disabled
											style="margin-top: 30px;"/>
										</v-list-item-title>
									</v-list-item-content>
									
									<v-list-item-action style="transform: scale(0.8); margin-top: 0px;">
										<v-btn fab small depressed color="red lighten-3"
										@click="uncompleteTask(cat, taskIndex)"
										style="margin-bottom: 10px;">
											<v-icon style="font-size: 1.5em">fa-redo-alt</v-icon>
										</v-btn>
										<v-btn fab small depressed>
											<v-icon color="grey lighten-1" style="font-size: 1.5em">
												fa-info
											</v-icon>
										</v-btn>
									</v-list-item-action>
								</v-list-item>
							</v-list>
							<v-btn small color="error" raised
							style="margin-bottom: 12px"
							@click="clearCompletedTasks">
								Clear Completed Tasks
							</v-btn>
						</div>
					</v-expand-transition>
					<v-list-item ripple @click="showCompletedTasks=!showCompletedTasks" v-show="board.completedTasks && completedTaskList.length > 0">
						<div style="margin: auto; padding: 4px;"> 
							<span style="font-size: 85%;" v-show="!showCompletedTasks">Completed<br></span>
							<v-icon>{{showCompletedTasks ? 'fa-chevron-up' : 'fa-chevron-down'}}</v-icon>
						</div>
					</v-list-item>
				</div>
			</div>
		</v-card>
		<div v-if="activeView == 'Kanban'" style="width: 100%; overflow-x: auto; overflow-y: show;">
			<draggable v-model="board.catMetadata.catOrder" @end="saveBoard()" draggable=".kanban-draggable"
			delay="200" :delayOnTouchOnly="true" ghostClass="tilted-card" chosenClass="tilted-card"
			handle=".kanban-draghandle" style="display: flex; justify-content: flex-start;">
				<v-card v-for="(cat, catIndex) in board.catMetadata.catOrder" :key="cat" 
				class="kanban-card kanban-draggable" style="min-height: 180px;">
					<v-toolbar :color="board.catMetadata.catColors[cat] || '#757575'" dark height=36 class="kanban-draghandle"
					@click="editCatDialog=cat; updateCatName=cat; colorSelectorVal={'hex': board.catMetadata.catColors[cat]}" style="cursor: pointer;">
						<h4 :style="{'margin': 'auto', 'color': contrastingTextColor(board.catMetadata.catColors[cat] || '#757575')}">{{cat}}</h4>
					</v-toolbar>
					<div style="height: calc(100% - 36px); display: flex; flex-direction: column; justify-content: space-between;">
						<draggable v-model="board.activeTasks[cat]" group="kanban" style="padding: 6px 18px; height: 100%;" v-if="board.activeTasks[cat]"
						delay="200" :delayOnTouchOnly="true" @end="saveBoard()" ghostClass="tilted-card" chosenClass="tilted-card">
							<div v-for="(task, taskIndex) in board.activeTasks[cat]" :key="task._id"
							style="background-color: #e0e0e0; border: 1px solid #ccc; box-shadow: 2px 2px 2px #ddd; border-radius: 4px; margin: 14px 4px;">
								<!--<div style="border-bottom: 1px solid #666" class="drag-handle">
									&bull;&bull;&bull;
								</div>-->
								<div @click="editTaskDialog=[cat, taskIndex]" style="cursor: pointer; margin: 8px; white-space: pre-wrap;">{{task.name}}</div>
								<div style="height: 1px; background-color: #444; margin-bottom: 8px;"/>
								<br>
								<v-list-item-subtitle style="text-align: left; margin-top: -22px; margin-left: 8px; margin-bottom: 6px; font-size: 90%;">
									<span @click="setDueDate(cat, taskIndex)" style="cursor: pointer; clear: none;">
										<span v-if="task.vagueDueDate != undefined || task.dueDate != undefined"
										:style="{'color': taskOverdue(task) ? '#da3434' : 'black' }">
											<v-icon style="font-size: 100%; margin: -4px 2px 0 0; color: inherit;">fa-calendar-alt</v-icon>
											{{task.vagueDueDate || toHumanTimeShort(task.dueDate)}}
										</span>
										<span v-else>
											Due: ?
										</span>
									</span>
									<span v-if="(task.activeSubtasks.length + task.completedSubtasks.length) > 0" @click="editTaskDialog=[cat, taskIndex]"
									style="float: right; display: inline-block; margin-right: 6px; cursor: pointer;">
										<v-icon style="font-size: 90%; margin: -2px 4px 0 0;">fa-check</v-icon>
										<sup>{{task.completedSubtasks.length}}</sup>&frasl;<sub>{{task.activeSubtasks.length + task.completedSubtasks.length}}</sub>
									</span>
								</v-list-item-subtitle>
							</div>
						</draggable>
						<div>
							<v-textarea outlined class="quickadd-textarea" :id="'quickadd-textarea-' + catIndex" auto-grow rows="1" small placeholder="New Task" @keyup.ctrl.enter="quickAddTask(cat, $event.target.value, false); clearTextareaElement($event.target)"
							style="padding: 5px; border-radius: 4px; width: calc(100% - 10px); margin: 0 5px; font-size: 100%;"/>
							<v-btn small style="width: calc(100% - 20px); margin: 2px 10px 10px 10px;" color="primary"
							@click="quickAddTask(cat, document.getElementById('quickadd-textarea-' + catIndex).value, false); clearTextareaElement(document.getElementById('quickadd-textarea-' + catIndex))">
								<v-icon :clickable="false">fa-plus</v-icon>
							</v-btn>
						</div>
					</div>
				</v-card>
				<draggable v-model="newCategoryByDrag" group="kanban" class="kanban-card" filter=".ignore-drag"
				style="border: 4px dashed green; border-radius: 8px; display: flex; flex-direction: column; align-items: space-around; justify-content: center;">
					<v-icon style="color: green; font-size: 200%; height: 100%; cursor: pointer;" @click="newCategoryByClick()" class="ignore-drag">fa-plus</v-icon>
				</draggable>
				<v-card class="kanban-card">
					<v-toolbar color="grey darken-1" dark height=36>
						<h4 style="margin: auto;">Settings</h4>
					</v-toolbar>
					<div style="margin-top: 22px;"> 
						<v-btn small color="#d27575" dark @click="clearEmptyCategories" style="padding: 0 15px; height: 42px;">
							CLEAR EMPTY<br>CATEGORIES
						</v-btn>
					</div>
				</v-card>
			</draggable>
		</div>
		<!-- Edit Category Dialog -->
		<v-dialog :value="editCatDialog !== undefined" max-width="600" persistent @keydown.esc="editCatDialog = (colorSelector) ? editCatDialog : undefined">
			<v-card v-if="editCatDialog !== undefined">
				<v-toolbar color="blue lighten-1">
					<span class="white--text headline">Edit Category</span>
					<div class="flex-grow-1"></div>
					<v-btn icon @click="editCatDialog = undefined">
						<v-icon>fa-times</v-icon>
					</v-btn>
				</v-toolbar>
				<div style="padding: 12px 22px;">
					<v-text-field v-model="updateCatName" label="New Category Name"/>
					<v-divider/>
					<v-dialog ref="colorDialog" v-model="colorSelector" @click:outside="colorSelector=false"
					full-width width="290" @keydown.esc="colorSelector=false">
						<template v-slot:activator="{ on }">
							<v-text-field
								:value="colorSelectorVal ? colorSelectorVal.hex : undefined"
								label="Category Color"
								placeholder="Click to Change Category Color"
								prepend-icon="fa-palette"
								readonly clearable
								@click:clear="colorSelectorVal = undefined; saveBoard();"
								clear-icon="fa-times"
								v-on="on"
								style="padding: 24px 26px 6px 26px;"
							></v-text-field>
						</template>
						<v-card>
							<v-color-picker flat v-model="colorSelectorVal" ref="colorPicker"/>
							<div style="display: flex; justify-content: flex-end;">
								<v-btn text color="primary" @click="colorSelector = undefined;">OK</v-btn>
							</div>
						</v-card>
					</v-dialog>
					<v-btn color="accent" @click="editCatDialog=undefined">Cancel</v-btn>
					&nbsp;&nbsp;
					<v-btn color="accent" @click="updateCatMetadata(editCatDialog, updateCatName)">Save</v-btn>
				</div>
			</v-card>
		</v-dialog>
		<!-- Edit Task Dialog -->
		<v-dialog :value="editTaskDialog !== undefined" max-width="600" @keydown.esc="editTaskDialog=undefined" id="editDialog"
		@click:outside="editTaskDialog = (dateSelector || categorySearch) ? editTaskDialog : undefined">
			<v-card v-if="editTaskDialog !== undefined">
				<v-toolbar color="blue lighten-1">
					<span class="white--text headline">Edit Task</span>
					<div class="flex-grow-1"></div>

					<v-btn icon @click="editTaskDialog = undefined">
						<v-icon>fa-times</v-icon>
					</v-btn>

				</v-toolbar>
				<div style="max-height: 78vh; overflow: auto;">
					<v-card-text style="padding-top: 0px;">
						<v-textarea label="Task" v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].name"
						outlined class="tasklist-textarea" auto-grow rows="1"
						@input="saveBoardTimeout" @blur="saveBoard"/>
						<v-divider/>
						<v-textarea placeholder="Description" outlined @input="saveBoardTimeout" @blur="saveBoard"
						v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].description"
						class="quickadd-textarea"/>
						<v-btn dark small color="green" style="margin: 12px;" @click="completeTask(...editTaskDialog); editTaskDialog=undefined;">&check; Complete Task</v-btn>
						<v-divider style="margin-bottom: 20px;"/>
						<v-combobox :value="editTaskDialog[0]" :items="Object.keys(board.activeTasks)" class="category-selector"
						label="Set Category" @focus="categorySearch=true" @blur="categorySearch=false; saveBoard()"
						@change="updateTaskCategory(editTaskDialog[0], editTaskDialog[1], $event)"
						dense clearable deletable-chips style="max-width: 300px; margin: auto; font-size: 94%;"
						@click:clear="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].category = undefined; saveBoard();" clear-icon="fa-times">
							<template v-slot:no-data>
								<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
									No matching category exists. Press <kbd>enter</kbd> to create a new one
									</v-list-item-title>
								</v-list-item-content>
								</v-list-item>
							</template>
						</v-combobox>
						<v-divider/>
					</v-card-text>
					<!-- Due Date Selector -->
					<v-dialog ref="dateDialog" v-model="dateSelector" :return-value.sync="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate"
					full-width width="290" @keydown.esc="dateSelector = false">
						<template v-slot:activator="{ on }">
							<v-text-field
								:value="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].vagueDueDate || toHumanTime(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate)"
								label="Due Date"
								prepend-icon="fa-calendar-plus"
								readonly clearable
								@click:clear="$set(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]], 'dueDate', undefined); $set(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]], 'vagueDueDate', undefined); saveBoard();"
								clear-icon="fa-times"
								v-on="on"
								style="padding: 24px 26px 6px 26px;"
							></v-text-field>
						</template>
						<v-date-picker v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate" scrollable reactive
						prev-icon="fa-chevron-left" next-icon="fa-chevron-right" id="date-picker"
						@change="$set(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]], 'vagueDueDate', undefined); saveBoard();">
							<v-divider style="margin: 10px 0;"/>
							<v-btn small color="primary" v-for="vdate in vagueDates" :key="vdate"
							:raised="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].vagueDueDate === vdate" :text="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].vagueDueDate !== vdate"
							@click="$set(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]], 'dueDate', undefined); $set(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]], 'vagueDueDate', vdate); $refs.dateDialog.save(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate); saveBoard();">
								{{vdate}}
							</v-btn>
							<v-divider style="margin: 10px 0;"/>
							<div style="display: flex; justify-content: flex-end;">
								<v-btn text color="primary" @click="$refs.dateDialog.save(board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate); saveBoard();">OK</v-btn>
							</div>
						</v-date-picker>
					</v-dialog>
					<v-menu
					:close-on-content-click="false"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					full-width
					min-width="290px">
						<v-date-picker v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].dueDate" 
						@input="dateSelector = false" />
					
					</v-menu>

					<v-divider></v-divider>

					<!-- Subtask List -->
					<v-list dense>
						<draggable v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].activeSubtasks" 
						@end="saveBoard()" handle=".drag-handle">
							<transition-group>
								<v-list-item v-for="(subtask, subtaskIndex) in board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].activeSubtasks" :key="subtask._id"
								ripple style="height: 90px;">
									<v-list-item-avatar class="drag-handle" color="teal" style="transform: scale(0.75);">
										<span class="white--text headline">{{subtaskIndex+1}}</span>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>
											<v-textarea :placeholder="'Subtask #' + (subtaskIndex + 1)" 
											v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].activeSubtasks[subtaskIndex].name" 
											@input="saveBoardTimeout" @blur="saveBoard" style="margin-top: 6px;"
											outlined class="tasklist-textarea subtask-textarea" auto-grow rows="1" />
										</v-list-item-title>
									</v-list-item-content>

									<v-list-item-action style="transform: scale(0.8); margin-top: 20px;">
										<v-btn fab small depressed color="green lighten-1"
										@click="completeSubtask(subtaskIndex)"
										style="margin-bottom: 10px;">
											<v-icon style="font-size: 1.5em">fa-check</v-icon>
										</v-btn>
									</v-list-item-action>
								</v-list-item>
							</transition-group>
						</draggable>
					</v-list>

					<v-btn fab small color="primary" @click="addSubtask" v-if="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].activeSubtasks && board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].activeSubtasks.length > 0">
						<v-icon>fa-plus</v-icon>
					</v-btn>
					<v-btn small color="primary" @click="addSubtask" v-else>
						Add Sub-Task
					</v-btn>
					<v-expand-transition>
						<div v-show="showCompletedSubtasks && board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks && board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks.length > 0">
							<v-divider style="margin-top: 18px"/>
							<h3 style="margin-top: 18px; margin-bottom: -12px;">Completed Tasks</h3>
							<v-list dense>
								<v-list-item v-for="(task, subtaskIndex) in board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks" :key="subtaskIndex"
								ripple style="height: 90px; transform: scale(0.85);">
									<v-list-item-avatar color="teal" style="transform: scale(0.75);">
										<span class="white--text headline">{{subtaskIndex+1}}</span>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>
											<v-text-field v-model="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks[subtaskIndex].name" 
											outlined disabled
											style="margin-top: 30px;"/>
										</v-list-item-title>
									</v-list-item-content>
									
									<v-list-item-action style="transform: scale(0.8); margin-top: 20px;">
										<v-btn fab small depressed color="red lighten-3"
										@click="uncompleteSubtask(subtaskIndex)"
										style="margin-bottom: 10px;">
											<v-icon style="font-size: 1.5em">fa-redo-alt</v-icon>
										</v-btn>
									</v-list-item-action>
								</v-list-item>
							</v-list>
							<v-btn small color="error" raised
							style="margin-bottom: 12px"
							@click="clearCompletedSubtasks">
								Clear Completed Tasks
							</v-btn>
						</div>
					</v-expand-transition>
					<v-divider style="margin-top: 18px;"/>
					<v-list-item ripple @click="showCompletedSubtasks=!showCompletedSubtasks" 
					v-show="board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks && board.activeTasks[editTaskDialog[0]][editTaskDialog[1]].completedSubtasks.length > 0">
						<div style="margin: auto; padding: 4px;"> 
							<span style="font-size: 85%;" v-show="!showCompletedSubtasks">Completed<br></span>
							<v-icon>{{showCompletedSubtasks ? 'fa-chevron-up' : 'fa-chevron-down'}}</v-icon>
						</div>
					</v-list-item>
					<v-divider/>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template> 

<script lang="ts">
import Vue from "vue";
import { TaskBoard, Task, Subtask } from "../../../src/task/taskboard.model";
import axios from "axios";
import mongoose from "mongoose";
import draggable from "vuedraggable";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import Tooltip from "tooltip.js";

export default Vue.extend({
	components: {
		draggable,
		FullCalendar,
	},
	props: {
		boardID: {
			type: String,
		},
		urlCat: {
			type: String,
			required: false,
		},
		urlTaskIndex: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			document,
			newCategoryByDrag: [] as Task[],
			categorySearch: "",
			vagueDates: ["ASAP", "Now", "Soon", "Later", "Eventually"],
			activeView: "Tasks",
			showCompletedTasks: false,
			showCompletedSubtasks: false,
			editCatDialog: undefined as string | undefined,
			editTaskDialog: undefined as [string, number] | undefined,
			dateSelector: false,
			colorSelector: false,
			updateCatName: "",
			colorSelectorVal: undefined,
			board: {} as TaskBoard,
			lastSavedBoard: "" as string,
			saveTimeout: undefined as number | undefined,
			calendarPlugins: [ dayGridPlugin ],
			calConfig: {
				header: {
					left: "prev,next,today",
					center: "title",
					right: "dayGridMonth,dayGridWeek,dayGridDay",
				},
			},
			windowFocused: true,
			refreshTimeoutInterval: 5, //time between auto-refreshes (in minutes)
			refreshTimeout: undefined as any,
		};
	},
	methods: {
		fetchBoard() {
			return axios.get(this.$store.state.apiURL + "/boards/" + this.boardID, this.axiosConfig)
			.then(resp => {
				this.board = resp.data;
				this.lastSavedBoard = JSON.stringify(this.board);
				//TODO: is allocating a new timeout every time inefficient??
				clearInterval(this.refreshTimeout);
				this.refreshTimeout = setTimeout(() => {
					this.fetchBoard();
				}, this.refreshTimeoutInterval  * 60 * 1000);
			})
			.catch(() => {
				this.$router.push("/");
			});

		},
		quickAddTask(cat: string | undefined, taskName: string, prepend = false) {
			// Only add if there is content in the textbox
			if (taskName.trim() === "") return;
			this.addTask(cat, taskName, prepend);
		},
		addTask(cat: string | undefined, taskName: string | undefined, prepend = false) {
			const category = cat || "Uncategorized";
			if (!this.board.activeTasks) this.board.activeTasks = {category: []};
			if (!this.board.activeTasks[category]) this.board.activeTasks[category] = [];
			const newTask = {
				_id: new mongoose.Types.ObjectId().toString(),
				name: taskName || "",
				description: "",
				lastUpdated: new Date(),
				dueDate: undefined,
				vagueDueDate: undefined,
				tags: [],
				activeSubtasks: [],
				completedSubtasks: [],
			} as Task;
			if (prepend) this.board.activeTasks[category].unshift(newTask);
			else this.board.activeTasks[category].push(newTask);
			const newTaskIndex = prepend ? 0 : (this.board.activeTasks[category].length - 1);
			setTimeout(() => {
				const textarea: any = document.querySelector("#tasklistentry-" + category + "-" + newTaskIndex + " .v-text-field__slot textarea");
				if (textarea) {
					const textareaRect = textarea.getBoundingClientRect();
					const absoluteElementTop = textareaRect.top + window.pageYOffset;
					const middle = absoluteElementTop - (window.innerHeight / 2);
					window.scrollTo(0, middle);
					textarea.focus();
				}
			}, 250);
			if (taskName) this.saveBoard();
		},
		createCat(cat: string, catData: Task[] = []) {
			this.$set(this.board.activeTasks, cat, catData);
			this.board.catMetadata.catOrder.push(cat);
		},
		deleteCat(cat: string) {
			this.$delete(this.board.activeTasks, cat);
			this.$set(this.board.catMetadata, "catOrder", this.board.catMetadata.catOrder.filter((x) => x !== cat));
		},
		newCategoryByClick() {
			const newCatName = prompt("What would you like to name your new category?");
			if (!newCatName) return;
			if (!this.board.activeTasks[newCatName]) {
				this.createCat(newCatName, []);
			} else {
				this.$toast("Category already exists, cannot create a duplicate category!");
			}
			this.saveBoard();
		},
		updateCatMetadata(oldCat: string, newCat: string) {
			if (newCat !== oldCat) {
				if (!this.board.activeTasks[newCat]) this.createCat(newCat);
				else this.$set(this.board.catMetadata.catColors, newCat, this.colorSelectorVal ? this.colorSelectorVal!["hex"] : undefined);
				this.$set(this.board.activeTasks, newCat, this.board.activeTasks[newCat].concat(this.board.activeTasks[oldCat]));
				if (oldCat === "Uncategorized") this.$set(this.board.activeTasks, oldCat, []);
				else this.deleteCat(oldCat);
			} else {
				this.$set(this.board.catMetadata.catColors, newCat, this.colorSelectorVal ? this.colorSelectorVal!["hex"] : undefined);
			}
			this.saveBoard().then(() => this.editCatDialog = undefined);
		},
		updateTaskCategory(taskCat: string, taskIndex: number, newCat: string) {
			const movedTask = this.board.activeTasks[taskCat].splice(taskIndex, 1);
			if (!this.board.activeTasks[newCat]) this.createCat(newCat);
			this.board.activeTasks[newCat].push(movedTask[0]);
			if (this.editTaskDialog !== undefined) this.editTaskDialog = [newCat, this.board.activeTasks[newCat].length - 1];
			this.saveBoard();
		},
		clearTextareaElement(elem: HTMLTextAreaElement) {
			if (elem) {
				elem.value = "";
				elem.dispatchEvent(new Event("input"));
			}
		},
		addSubtask() {
			if (this.editTaskDialog === undefined) return;
			if (!this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks) this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks = [];
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks.push({
				_id: new mongoose.Types.ObjectId().toString(),
				name: "",
			} as Subtask);
		},
		completeTask(taskCat: string, taskIndex: number) {
			if (!this.board.completedTasks) this.board.completedTasks = {};
			if (this.board.activeTasks[taskCat][taskIndex].activeSubtasks.length > 0) {
				if (!confirm("Are you sure you want to delete a task with active subtasks?")) {
					return;
				}
			}
			const completedTask = this.board.activeTasks[taskCat].splice(taskIndex, 1);
			if (!this.board.completedTasks[taskCat]) this.board.completedTasks[taskCat] = [];
			this.board.completedTasks[taskCat].push(completedTask[0]);
			this.saveBoard();
		},
		uncompleteTask(taskCat: string, taskIndex: number) {
			const uncompletedTask = this.board.completedTasks[taskCat].splice(taskIndex, 1);
			this.board.activeTasks[taskCat].push(uncompletedTask[0]);
			this.saveBoard();
		},
		completeSubtask(subtaskIndex: number) {
			if (this.editTaskDialog === undefined) return;
			if (!this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks) this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks = [];
			const completedSubtask = this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks.splice(subtaskIndex, 1);
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks.push(completedSubtask[0]);
			this.saveBoard();
		},
		uncompleteSubtask(subtaskIndex: number) {
			if (this.editTaskDialog === undefined) return;
			if (!this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks) this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks = [];
			const uncompletedSubtask = this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks.splice(subtaskIndex, 1);
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].activeSubtasks.push(uncompletedSubtask[0]);
			this.saveBoard();
		},
		cleanLostCategoryOrders() {
			this.$set(this.board.catMetadata, "catOrder", this.board.catMetadata.catOrder.filter(x => Object.keys(this.board.activeTasks).includes(x)));
		},
		clearEmptyCategories() {
			Object.keys(this.board.activeTasks).reduce((agg: string[], cat: string) => {
				if (cat === "Uncategorized") return agg;
				if (this.board.activeTasks[cat].length < 1) agg.push(cat);
				return agg;
			}, []).forEach((cat: string) => this.deleteCat(cat));
			this.cleanLostCategoryOrders();
			this.saveBoard();
		},
		clearCompletedTasks() {
			this.board.completedTasks = {};
			this.showCompletedTasks = false;
			this.saveBoard();
		},
		clearCompletedSubtasks() {
			if (this.editTaskDialog === undefined) return;
			this.board.activeTasks[this.editTaskDialog[0]][this.editTaskDialog[1]].completedSubtasks = [];
			this.showCompletedSubtasks = false;
			this.saveBoard();
		},
		saveBoard(): Promise<any> {
			// Clear currently running timeouts for board save
			clearTimeout(this.saveTimeout);
			// Only attempt to save data to server if something has changed since the last save
			if (this.lastSavedBoard !== JSON.stringify(this.board)) {
				// Delete empty task entries
				if (this.editTaskDialog === undefined) {
					Object.entries(this.board.activeTasks).forEach(([cat, taskList]) => {
						this.$set(this.board.activeTasks, cat, this.board.activeTasks[cat].filter((task) => {
							return task.name !== "" || task.description !== "" || task.dueDate !== undefined || task.vagueDueDate !== undefined;
						}));
					});
				}
				this.board.lastUpdated = new Date();
				// Send save data to server!
				return axios.post(this.$store.state.apiURL + "/boards/edit", this.board, this.axiosConfig)
				.then(resp => {
					this.$toast("Saved tasks!");
					// Reset last saved board if save successful
					this.lastSavedBoard = JSON.stringify(this.board);
				});
			}
			return Promise.resolve();
		},
		saveBoardTimeout() {
			clearTimeout(this.saveTimeout);
			this.saveTimeout = window.setTimeout(() => {
				this.saveBoard();
			}, 1200);
		},
		saveBoardActiveView() {
			this.$store.commit("setBoardActiveView", [this.board._id, this.activeView]);
		},
		toHumanTime(date: string | undefined): string {
			if (!date) return "";
			if (this.vagueDates.includes(date)) return date;
			const parsedDate = moment(date);
			return parsedDate.isValid() ? parsedDate.format("MMM Do YYYY") : date;
		},
		toHumanTimeShort(date: string | undefined): string {
			if (!date) return "";
			if (this.vagueDates.includes(date)) return date;
			const parsedDate = moment(date);
			return parsedDate.isValid() ? parsedDate.format("M/D/YY") : date;
		},
		setDueDate(taskCat: string, taskIndex: number) {
			this.editTaskDialog = [taskCat, taskIndex];
			setTimeout(() => {this.dateSelector = true; }, 500);
		},
		calendarEventClick(e: any) {
			this.editTaskDialog = [e.event.extendedProps.cat, e.event.extendedProps.taskIndex];
		},
		calendarEventRender(info: any) {
			const tooltip = new Tooltip(info.el, {
				title: info.event.title,
				placement: "top",
				trigger: "hover",
				container: "body",
			});
		},
		vagueToDayDiff(v: string | undefined): number {
				if (v === "ASAP") return 0.5;
				if (v === "Now") return 1.5;
				if (v === "Soon") return 14;
				if (v === "Later") return 60;
				if (v === "Eventually") return 365 * 10;
				return 30;
		},
		taskOverdue(task: Task) {
			if (task.dueDate != undefined && moment(task.dueDate).diff(moment(new Date()), "days") < 1) return true
			if (task.vagueDueDate != undefined && this.vagueToDayDiff(task.vagueDueDate) < 1) return true
			return false
		},
		taskDueSort(aTask: [string, number, Task], bTask: [string, number, Task]): number {
			// Prefer to use diff between exact due date and today
			// else will use vague date if available
			// else will use the date the task was created (most recent tasks will be at the top)
			const aa = (aTask[2].dueDate ? moment(aTask[2].dueDate).diff(moment(new Date()), "days", true) : undefined) || this.vagueToDayDiff(aTask[2].vagueDueDate) || moment(new mongoose.Types.ObjectId(aTask[2]._id).getTimestamp()).diff(moment(new Date()), "days");
			const bb = (bTask[2].dueDate ? moment(bTask[2].dueDate).diff(moment(new Date()), "days", true) : undefined) || this.vagueToDayDiff(bTask[2].vagueDueDate) || moment(new mongoose.Types.ObjectId(aTask[2]._id).getTimestamp()).diff(moment(new Date()), "days");
			return (aa > bb) ? 1 : -1;
		},
		addWindowBlurListeners() {
			const browserPrefixes = ["moz", "ms", "o", "webkit"];
			// get the correct attribute name
			function getHiddenPropertyName(prefix: string | undefined): string {
				return (prefix ? prefix + "Hidden" : "hidden");
			}

			// get the correct event name
			function getVisibilityEvent(prefix: string | undefined) {
				return (prefix ? prefix : "") + "visibilitychange";
			}

			// get current browser vendor prefix
			function getBrowserPrefix() {
				for (const prefix of browserPrefixes) {
					if (getHiddenPropertyName(prefix) in document) {
						// return vendor prefix
						return prefix;
					}
				}

				// no vendor prefix needed
				return undefined;
			}

			// bind and handle events
			const browserPrefix = getBrowserPrefix();
			const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
			const visibilityEventName = getVisibilityEvent(browserPrefix);

			const onVisible = () => {
				// prevent double execution
				if (this.windowFocused) {
					return;
				}
				// change flag value
				this.windowFocused = true;
				// Update board data
				return this.fetchBoard();
			};

			const onHidden = () => {
				// prevent double execution
				if (!this.windowFocused) {
					return;
				}
				// change flag value
				this.windowFocused = false;
				// Save board data
				this.saveBoard();
			};

			function handleVisibilityChange(forcedFlag: boolean | Event) {
			// forcedFlag is a boolean when this event handler is triggered by a
			// focus or blur eventotherwise it's an Event object
				if (typeof forcedFlag === "boolean") {
					if (forcedFlag) {
						return onVisible();
					}
					return onHidden();
				}
				if ((document as any)[hiddenPropertyName]) {
					return onHidden();
				}
				return onVisible();
			}
			document.addEventListener(visibilityEventName, handleVisibilityChange, false);
			// extra event listeners for better behaviour
			document.addEventListener("focus", () => {
				handleVisibilityChange(true);
			}, false);

			document.addEventListener("blur", () => {
				handleVisibilityChange(false);
			}, false);
			window.addEventListener("focus", () => {
				handleVisibilityChange(true);
			}, false);
			window.addEventListener("blur", () => {
				handleVisibilityChange(false);
			}, false);
		},
		loadUrlParam(): any {
			if (this.urlCat && this.urlTaskIndex) {
				this.editTaskDialog = [this.urlCat, parseInt(this.urlTaskIndex, undefined)];
				this.$router.replace("/board/" + this.board._id);
			}
		},
		contrastingTextColor(bgColor: string): string {
			bgColor = (bgColor.charAt(0) === "#") ? bgColor.substring(1, 7) : bgColor;
			const r = parseInt(bgColor.substring(0, 2), 16); // hexToR
			const g = parseInt(bgColor.substring(2, 4), 16); // hexToG
			const b = parseInt(bgColor.substring(4, 6), 16); // hexToB
			const uicolors = [r / 255, g / 255, b / 255];
			const c = uicolors.map((col) => {
				if (col <= 0.03928) {
				return col / 12.92;
				}
				return Math.pow((col + 0.055) / 1.055, 2.4);
			});
			const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
			return (L > 0.179) ? "#222" : "#EEE";
		},
	},
	computed: {
		isTouchScreen(): boolean {
			return("ontouchstart" in window);
		},
		chronoTaskList(): Array<[string, number, Task]> {
			if (!this.board.activeTasks) return new Array();
			return (Object.keys(this.board.activeTasks) as any[]).reduce((agg: Array<[string, number, Task]>, cat: string) => {
				return agg.concat(this.board.activeTasks[cat].map((task: Task, i: number) => [cat, i, task]));
			}, new Array()).sort(this.taskDueSort);
		},
		completedTaskList(): Array<[string, number, Task]> {
			if (!this.board.completedTasks) return new Array();
			return (Object.keys(this.board.completedTasks) as any[]).reduce((agg: Array<[string, number, Task]>, cat: string) => {
				return agg.concat(this.board.completedTasks[cat].map((task: Task, i: number) => [cat, i, task]));
			}, new Array()).reverse();
		},
		calendarEvents(): any {
			return Object.entries(this.board.activeTasks).reduce((agg, [cat, tasks]: [string, Task[]]) => {
				return agg.concat(...tasks.map((task, taskIndex) => {
					return {
						id: [cat, taskIndex],
						extendedProps: {taskIndex, cat},
						title: task.name,
						date: task.dueDate,
					};
				}));
			}, [] as any[]);
		},
		axiosConfig(): any {
			return {
				headers: { Authorization: "Bearer " + this.$store.state.jwtToken},
			};
		},
	},
	watch: {
		boardID() {
			this.fetchBoard().then(() => {
				if (this.board._id && this.$store.state.boardActiveView[this.board._id]) {
					this.activeView = this.$store.state.boardActiveView[this.board._id];
				}
			});
		},
		newCategoryByDrag() {
			setTimeout(() => {
				if (this.newCategoryByDrag.length > 0) {
					const newCatName = prompt("What would you like to name your new category?") || "Uncategorized";
					if (!this.board.activeTasks[newCatName]) {
						this.createCat(newCatName, this.newCategoryByDrag);
					} else {
						this.$set(this.board.activeTasks, newCatName, this.board.activeTasks[newCatName].concat(this.newCategoryByDrag));
					}
					this.newCategoryByDrag = [];
					this.saveBoard();
				}
			}, 200);
		},
		"$route"(/*to: any, from: any*/) {
			this.loadUrlParam();
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchBoard().then(() => {
				this.addWindowBlurListeners();
				if (this.board._id && this.$store.state.boardActiveView[this.board._id]) {
					this.activeView = this.$store.state.boardActiveView[this.board._id];
				}
				this.loadUrlParam();
			});
		});
	},
});
</script>

<style lang="scss">
	.kanban-card {
		margin: 30px 10px 60px 10px;
		min-width: 180px; 
		max-width: 240px; 
		font-size: 76%;
		user-select: none;
	}
	.drag-handle {
		cursor: all-scroll;
	}
	/* Board View Selector */
	.v-input__prepend-outer {
		margin-right: 16px !important;
	}
	.v-select__selections input { display: none; }
	.v-select__selection { margin: auto !important; }
	.view-switcher .v-input__append-inner { width: 0px; }
	.category-selector .v-input__append-inner { width: 16px; }
	/* Tasklist Textarea */
	.tasklist-textarea:not(.subtask-textarea) {
		margin-top: 30px !important;
	}
	.tasklist-textarea textarea {
		margin: 1px 1px 1.2px 2px !important;
		max-height: 60px;
		overflow-y: auto !important;
	}
	.tasklist-textarea .v-text-field__details {
		display: none;
	}
	.quickadd-textarea .v-input__slot {
		margin: 0px;
		min-height: 0px !important;
		height: auto;
	}
	.quickadd-textarea textarea {
		padding: 6px 0;
		line-height: 100%;
		margin: 0px !important;
	}
	.quickadd-textarea .v-text-field__details {
		display: none;
	}
	.tilted-card {
		transform: rotate(8deg);
	}
	.tasklist-textarea-printable {
		display: none;
	}
	@media print {
		.v-content {
			padding: 0px !important;
		}
		.tasklist-textarea, .task-add-btn, .v-footer, .task-list-action-btns, .v-toolbar.v-app-bar, .v-snack, .completed-task-list {
			display: none;
		}
		.tasklist-textarea-printable {
			display: flex !important;
			margin-top: 12px;
			margin-bottom: 12px;
			padding-bottom: 6px;
			border-bottom: 1px dashed black;
			white-space: pre-line;
			word-wrap: break-word;
			text-align: left;
		}
	}
</style>
<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-next-button, .fc-prev-button {
	padding-bottom: 12px;
	padding-top: 0px;
	margin: 0 3px;
}
.fc-title {
	color: white;
}
</style>

<style scoped>
	#date-picker >>> .v-picker__actions {
		display: block;
	}
	#date-picker >>> .v-date-picker-table {
		height: auto;
	}
</style>

<style lang="scss">
	$toolbar-color: #555;
	.popper, .tooltip {
		position: absolute;
		z-index: 9999;
		background: $toolbar-color;
		color: white;
		width: 150px;
		border-radius: 3px;
		box-shadow: 0 0 2px rgba(0,0,0,0.5);
		padding: 10px;
		text-align: center;
		font-family: sans-serif;
		font-size: 90%;
	}
	.style5 .tooltip {
		background: #1E252B;
		color: #FFFFFF;
		max-width: 200px;
		width: auto;
		font-size: .8rem;
		padding: .5em 1em;
	}
	.popper .popper__arrow,
	.tooltip .tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
	}

	.tooltip .tooltip-arrow,
	.popper .popper__arrow {
		border-color: $toolbar-color;
	}
	.style5 .tooltip .tooltip-arrow {
		border-color: #1E252B;
	}
	.popper[x-placement^="top"],
	.tooltip[x-placement^="top"] {
		margin-bottom: 5px;
	}
	.popper[x-placement^="top"] .popper__arrow,
	.tooltip[x-placement^="top"] .tooltip-arrow {
		border-width: 5px 5px 0 5px;
		border-left-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		bottom: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}
	.popper[x-placement^="bottom"],
	.tooltip[x-placement^="bottom"] {
		margin-top: 5px;
	}
	.tooltip[x-placement^="bottom"] .tooltip-arrow,
	.popper[x-placement^="bottom"] .popper__arrow {
		border-width: 0 5px 5px 5px;
		border-left-color: transparent;
		border-right-color: transparent;
		border-top-color: transparent;
		top: -5px;
		left: calc(50% - 5px);
		margin-top: 0;
		margin-bottom: 0;
	}
	.tooltip[x-placement^="right"],
	.popper[x-placement^="right"] {
		margin-left: 5px;
	}
	.popper[x-placement^="right"] .popper__arrow,
	.tooltip[x-placement^="right"] .tooltip-arrow {
		border-width: 5px 5px 5px 0;
		border-left-color: transparent;
		border-top-color: transparent;
		border-bottom-color: transparent;
		left: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}
	.popper[x-placement^="left"],
	.tooltip[x-placement^="left"] {
		margin-right: 5px;
	}
	.popper[x-placement^="left"] .popper__arrow,
	.tooltip[x-placement^="left"] .tooltip-arrow {
		border-width: 5px 0 5px 5px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		right: -5px;
		top: calc(50% - 5px);
		margin-left: 0;
		margin-right: 0;
	}
</style>
